import React, { useState, useEffect, useContext } from "react";
import ImagesSlide from "./ImagesSlide";
import { Row, Col, Space, Modal, Typography } from "antd";
import styled from "styled-components";
import { PriceNumber, UpdateCart } from "functions/index";
import { CouterCartContext } from "store/CouterCartProvider";
import {
  TitleDarkPurple,
  TitleOrange,
  TextLightGrey,
  TitleDarkGrey,
} from "components/styled-component/TextStyle";
import { InputAmount } from "components/Inputs";
import ButtonAddToCart from "components/buttons/ButtonAddToCart";
import { ButtonOptions } from "components/buttons";
const { Text } = Typography;
function DetailBox() {
  const { addProduct, productDetail, product, setProduct } =
    useContext(CouterCartContext);
  const arrColor = productDetail?.product_in_set?.map((item) => {
    return { id: item.id, color: item.color };
  });
  const [currentImg, setCurrentImage] = useState("");

  const setMarginTop = (size) => {
    return { marginTop: size, marginBottom: size };
  };
  useEffect(() => {
    setCurrentImage(productDetail?.product_list_images?.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail]);
  const onValueUpdate = (value) => {
    const result = {
      ...product,
      amount: value,
    };
    setProduct(result);
  };
  const onClickAddToCart = () => {
    if (!product.product_list_id) {
      Modal.warning({
        title: "แจ้งเตือน!",
        content: "กรุณาระบุสีของสินค้า",
        okText: "ตกลง",
      });
    } else {
      UpdateCart(product, "products", callBackSuccess);
    }
  };
  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    setProduct({
      amount: 1,
      product_list_id: "",
    });
  };
  const onClickColor = (item) => {
    const result = {
      ...product,
      product_list_id: item.id,
    };
    const found = productDetail.product_in_set.find((e) => e.id === item.id);
    setCurrentImage(found?.product_list_images?.path);
    setProduct({ ...result });
  };
  return (
    <Row justify="space-around" gutter={[16, 16]}>
      <Col xl={10} lg={12} sm={12} xs={24}>
        <ImagesSlide
          defaultImage={currentImg}
          images={productDetail?.product_in_set.map(
            (img) => img.product_list_images.path
          )}
        />
      </Col>
      <Col xl={12} lg={12} sm={12} xs={22}>
        <div
          style={{
            background: "white",
            padding: "20px 20px",
            borderRadius: "10px",
            marginBottom: "2rem",
          }}
        >
          <TitleDarkPurple level={4}>{productDetail.name}</TitleDarkPurple>

          <DivFlex style={{ marginTop: "1rem"}}>
            <TitleOrange level={3}>
              {PriceNumber(productDetail.price_show)}
            </TitleOrange>
            <TextLightGrey
              style={{ marginLeft: "10px", marginTop: "7px" }}
              type="12px"
            >
              ราคาสินค้า (บาท)
            </TextLightGrey>
          </DivFlex>
          <Text style={{ marginLeft: "10px" }} type="12px">
            {productDetail.detail}
          </Text>
        </div>

        <div>
          <TitleDarkGrey level={5}>ระบุสีของสินค้า</TitleDarkGrey>
          <div>
            <Space size={[16, 16]} wrap style={{ marginTop: "0.5rem" }}>
              {arrColor?.map((item, index) => {
                return (
                  <ButtonOptions key={index} onClick={() => onClickColor(item)}>
                    {item.color}
                  </ButtonOptions>
                );
              })}
            </Space>
          </div>
        </div>
        <div style={setMarginTop("2vh")}>
          <TitleDarkGrey level={5}>ระบุจำนวนสินค้า</TitleDarkGrey>
          <Row gutter={16} style={{ marginTop: "1rem" }}>
            <Col xl={9} sm={10} xs={12}>
              <InputAmount onValueUpdate={onValueUpdate} />
            </Col>
            <Col xl={9} sm={10} xs={12}>
              <ButtonAddToCart
                style={{ height: "32px" }}
                size="14px"
                onClick={onClickAddToCart}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default DetailBox;
const DivFlex = styled.div`
  display: flex;
`;
