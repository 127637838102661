import React from "react";
import LoadingImage from "images/loading.gif";

export default function Loading() {
  return (
    <div
      style={{
        display: "block",
        marginTop: "20%",
        textAlign: "center",
        height: "40vh",
      }}
    >
      <img width={80} height={80} alt="gif" src={LoadingImage}></img>
      <p style={{ marginTop: "1rem" }}>Loading...</p>
    </div>
  );
}
