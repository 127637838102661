import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  message,
  Form,
  Input,
  Row,
  Col,
  Radio,
  Typography,
  Divider,
  Modal,
} from "antd";
import {
  faHome,
  faShippingFast,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import {
  TextDarkGrey,
  TitleLightPurple,
  TitleDarkGrey,
  TitleRed,
} from "components/styled-component/TextStyle";
import { LabelHeader } from "components/Labels";
import { PriceNumber } from "functions";
import { ButtonBack } from "components/buttons";
import { URL_PATH } from "services/host";
import { ButtonOrange } from "components/styled-component/ButtonStyled";
import { AXIOS } from "services/host";
import { API_CREATE_ORDER } from "services/apis";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CouterCartContext } from "store/CouterCartProvider";
const { confirm } = Modal;
const { Text } = Typography;
function OrderDetail(props) {
  const { data } = props;
  const navigator = useNavigate();
  const shipment = data?.store_data?.shipment;
  const bank = data?.store_data?.bank;
  const [checked, setChecked] = useState({ shipment_id: "", bank_id: "" });
  const { reducerShipmentPrice, shipment_price, reducerSetCouterCart } =
    useContext(CouterCartContext);
  const showPromiseConfirm = (form) => {
    if (checked.bank_id && checked.shipment_id) {
      onFinish(form);
    } else {
      Modal.warning({
        title: "แจ้งเตือน",
        content: "กรุณาเลือกวิธีการจัดส่ง หรือ วิธีการชำระเงิน",
        okText: "ตกลง",
        onOk: () => {},
      });
    }
  };
  const onFinish = (form) => {
    confirm({
      title: "ยืนยันการสั่งซื้อสินค้า?",
      icon: <ExclamationCircleOutlined />,
      content: "กรุณาตรวจสอบรายการสินค้าก่อนกดยืนยันการสั่งซื้อสินค้า",
      onOk() {
        return new Promise((resolve, reject) => {
          reducerSetCouterCart(null);
          reducerShipmentPrice(0);
          const formData = {
            ...form,
            bank_id: checked.bank_id,
            shipment_id: checked.shipment_id,
          };
          const data_cart_list = JSON.parse(
            localStorage.getItem(`localProduct`)
          );
          const data = {
            ...formData,
            data_cart_list,
          };
          AXIOS({
            method: "post",
            url: API_CREATE_ORDER,
            data: data,
          })
            .then((res) => {
              const resData = res.data.data;
              localStorage.clear();
              Modal.success({
                title: "สั่งซื้อสินค้าสำเร็จ",
                content: "ไปหน้าอัปโหลดหลักฐานการชำระเงิน",
                okText: "ตกลง",
                onOk: () =>
                  navigator(`/page-reciept/?reciept_id=${resData.ref_code}`),
              });
              resolve();
            })
            .catch((err) => {
              reject();
            });
        }).catch(() => message.error("เกิดข้อผิดพลาด"));
      },
    });
  };
  return (
    <DivRoot>
      <LabelHeader text="ที่อยู่สำหรับจัดส่งสินค้า" icon={faHome} />
      <Form
        name="address"
        layout="vertical"
        onFinish={showPromiseConfirm}
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col xl={8} sm={8} xs={24}>
            <Form.Item
              label="ชื่อ"
              name="first_name"
              rules={[{ required: true, message: "กรุณาระบุชื่อ" }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col xl={8} sm={8} xs={24}>
            <Form.Item
              label="นามสกุล"
              name="last_name"
              rules={[{ required: true, message: "กรุณาระบุนามสกุล" }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col xl={8} sm={8} xs={24}>
            <Form.Item
              label="เบอร์โทรศัพท์"
              name="phone"
              rules={[{ required: true, message: "กรุณาระบุเบอร์โทรศัพท์" }]}
            >
              <Input
                maxLength={10}
                type="tel"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24}>
            <Form.Item
              label="รายละเอียดที่อยู่"
              name="address"
              rules={[
                { required: true, message: "กรุณาระบุรายละเอียดที่อยู่" },
              ]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label="ตำบล"
              name="sub_district"
              rules={[{ required: true, message: "กรุณาระบุตำบล" }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label="อำเภอ"
              name="district"
              rules={[{ required: true, message: "กรุณาระบุอำเภอ" }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label="จังหวัด"
              name="province"
              rules={[{ required: true, message: "กรุณาระบุจังหวัด" }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              type="number"
              label="รหัสไปรษณีย์"
              name="postcode"
              rules={[{ required: true, message: "กรุณาระบุรหัสไปรษณีย์" }]}
            >
              <Input
                maxLength={5}
                type="tel"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div>
          <LabelHeader text="เลือกวิธีการจัดส่ง" icon={faShippingFast} />
          <Row gutter={[8, 8]}>
            {shipment?.map((item, index) => (
              <Col key={index} xl={12} sm={12} xs={24}>
                <DivRadio>
                  <Row align="middle">
                    <Col span={12}>
                      <Radio
                        checked={checked.shipment_id === item.id}
                        onChange={() => {
                          setChecked({ ...checked, shipment_id: item.id });
                          reducerShipmentPrice(item.price);
                        }}
                      >
                        <img
                          alt="imagwship"
                          width={"100%"}
                          height="50px"
                          src={`${URL_PATH}${item.image_file}`}
                        />
                      </Radio>
                    </Col>

                    <Col span={12}>
                      <Row>
                        <Col span={24}>
                          <TitleLightPurple
                            style={{ fontSize: "14px", marginBottom: "0px" }}
                          >{`${PriceNumber(
                            item.price_show
                          )} บาท`}</TitleLightPurple>
                        </Col>
                        <Col span={24}>
                          <p style={{ color: "#797979", marginBottom: "5px" }}>
                            {item.detail}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </DivRadio>
              </Col>
            ))}
          </Row>
        </div>
        <div style={{ marginTop: "1.5rem" }}>
          <LabelHeader text="เลือกวิธีการชำระเงิน" icon={faCreditCard} />
          <Row gutter={[8, 8]}>
            {bank?.map((item, index) => (
              <Col key={index} xl={24} sm={24} xs={24}>
                <DivRadio key={index}>
                  <Row align="middle" gutter={[8, 8]}>
                    <Col xl={6} lg={6} sm={6} xs={12}>
                      <Radio
                        checked={checked.bank_id === item.id}
                        onChange={() =>
                          setChecked({ ...checked, bank_id: item.id })
                        }
                      >
                        <img
                          alt="imagwship"
                          width={"100%"}
                          height="50px"
                          src={`${URL_PATH}${item?.image_file}`}
                        />
                      </Radio>
                    </Col>
                    <Col xl={18} lg={18} sm={14}>
                      <Row>
                        <Col span={24}>
                          <p style={{ color: "#797979", marginBottom: "5px" }}>
                            {`ธนาคาร ${item?.name} สาขา ${item?.branch}`}
                          </p>
                        </Col>
                        <Col xl={9} sm={24} xs={24}>
                          <TextDarkGrey type="14px">
                            เลขที่บัญชี
                            <Text
                              style={{ margin: "0px 5px 0px 5px" }}
                              strong
                              type="danger"
                            >
                              {item?.account_number}
                            </Text>
                          </TextDarkGrey>
                        </Col>
                        <Col xl={12} xs={24}>
                          ชื่อบัญชี
                          <Text style={{ margin: "0px 5px 0px 5px" }} strong>
                            {item?.owner_name}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </DivRadio>
              </Col>
            ))}
          </Row>
        </div>
        <Row>
          <Col span={24} style={{ marginTop: "2rem" }}>
            <Row gutter={8} justify="space-between" align="middle">
              <Col xl={18} lg={15}>
                <TitleDarkGrey level={5}>สรุปยอดชำระ</TitleDarkGrey>
              </Col>
              <Col xl={6} lg={9} style={{ textAlign: "end" }}>
                <TitleRed level={4}>{`${PriceNumber(
                  data?.total_price + shipment_price
                )} บาท`}</TitleRed>
              </Col>
            </Row>
          </Col>
          <Divider dashed />
          <Col span={24}>
            <Row justify="space-between" gutter={8}>
              <Col xl={5} xs={12}>
                <ButtonBack htmlType="button" />
              </Col>
              <Col xl={5} xs={12}>
                <ButtonOrange htmlType="submit">ยืนยันคำสั่งซื้อ</ButtonOrange>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </DivRoot>
  );
}

export default OrderDetail;
const DivRadio = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background: #ffffff;
  padding: 1rem 10px 1rem 10px;
`;
const DivRoot = styled.div`
  border-radius: 10px;
  background: #fafafa;
  padding: 1rem 2rem 1rem 2rem;
`;
const FormInput = styled(Input)`
  border-radius: 5px !important;
`;
