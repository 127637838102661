import React, { useState } from "react";
import { ButtonAddToCart } from "components/buttons";
import ModalSelectBox from "./modalSelectBox";
import { Row, Col } from "antd";
import { Typography } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { PriceNumber } from "functions";
import { ButtonSet } from "components/styled-component/ButtonStyled";
import { RenderImage } from "functions";

const { Paragraph } = Typography;
function CardProduct(props) {
  const { data } = props;
  const navigator = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    visible: false,
    item: null,
  });
  const onClickDetail = ({ product_lists }) => {
    const { product_id } = product_lists[0];
    navigator(`/detail?v=${encodeURIComponent(product_id)}`);
  };

  const onSelectBox = (item) => {
    setIsModalOpen({ visible: true, item });
  };
  return (
    <div
      style={{
        padding: "5px",
        backgroundColor: "#ffff",
        borderRadius: 10,
      }}
    >
      <Row gutter={[4, 4]}>
        <ImageProduct
          onClick={() =>
            navigator(
              `/detail?v=${encodeURIComponent(
                data?.product_lists[0]?.product_id
              )}`
            )
          }
          alt="example"
          src={
            RenderImage(data?.product_cover_images?.path)
          }
        />

        <Col xl={24} sm={24} xs={24}>
          <TextProductName
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigator(
                `/detail?v=${encodeURIComponent(
                  data?.product_lists[0]?.product_id
                )}`
              )
            }
            ellipsis={{
              rows: 1,
              tooltip: [<TooltipText>{data?.name}</TooltipText>],
            }}
          >
            {data?.name}
          </TextProductName>
        </Col>
        <Col xl={24} sm={24} xs={24}>
          <TextPrice>{`${PriceNumber(data?.price_show)} THB`}</TextPrice>
        </Col>
        <Col xl={12} sm={24} xs={24}>
          <ButtonSet onClick={() => onClickDetail(data)}>
            เซ็ตพร้อมสูบ
          </ButtonSet>
        </Col>
        <Col xl={12} sm={24} xs={24}>
          <ButtonAddToCart onClick={() => onSelectBox(data)} />
        </Col>
      </Row>
      <ModalSelectBox
        path={`/detail?v=${encodeURIComponent(
          data?.product_lists[0]?.product_id
        )}`}
        visible={isModalOpen.visible}
        item={isModalOpen.item}
        onHiding={() => setIsModalOpen({ visible: false, item: null })}
      />
    </div>
  );
}

export default CardProduct;
const ImageProduct = styled.img`
  margin: auto;
  cursor: pointer;
  width: 8rem;
  height: 9rem;
  @media (max-width: 600px) {
    height: 6rem;
  }
`;
const TooltipText = styled.span`
  font-size: 12px;
`;
const TextPrice = styled.span`
  color: #4a67fa;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;
const TextProductName = styled(Paragraph)`
  font-weight: 500;
  color: #12143e;
  font-size: 14px;
`;
