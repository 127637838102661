import axios from "axios";

const UAT = "https://staging-api.humnoi-firelook.com/api/v1";
const UAT_IMAGE = "https://staging-api.humnoi-firelook.com";

const PRODUCTION = "https://api.humnoi-firelook.com/api/v1";
const PROD_IMAGE = "https://api.humnoi-firelook.com";

export const AXIOS = axios.create({
  baseURL: PRODUCTION,
});

export const URL_PATH = PROD_IMAGE;
