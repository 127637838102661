/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { API_GET_PRODUCT } from "services/apis";
import { AXIOS } from "services/host";

export default function UseAllProduct(pageNumber, catId, keyword) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalRow, setTotalRow] = useState(10);
  const [stateKeyword, setStateKeyword] = useState("");

  const fetchData = () => {
    setLoading(true);
    AXIOS({
      url: API_GET_PRODUCT,
      method: "get",
      params: {
        category_id: catId ?? "",
        page: pageNumber,
        limit: 8,
        search: keyword,
      },
    })
      .then((res) => {
        const result = res.data.data;
        const updateData = [...products, ...result.rows];
        setProducts((prevProduts) => {
          return [...new Set([...prevProduts, ...result.rows])];
        });
        setHasMore(updateData.length < result.total_rows && !keyword);
          setTotalRow(result.total_rows);
          setLoading(false);
      })
      .catch((err) => {
        setProducts(["500"]);
        setLoading(false);
        setHasMore(false);
        setTotalRow(0);
        return;
      });
  };
  useEffect(() => {
    if (products?.length === 0) {
      fetchData();
    }
  }, [products]);
  const onBeforeFetchDataByPage = () => {
    if (pageNumber !== 1) {
      fetchData();
    }
  };
  const onBeforeFetchDataByCatId = () => {
    setProducts([]);
    setStateKeyword("");
  };
  useEffect(onBeforeFetchDataByPage, [pageNumber]);
  useMemo(onBeforeFetchDataByCatId, [catId, keyword]);

  return { loading, products, hasMore, totalRow };
}
