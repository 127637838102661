import React, { useState, useContext, useEffect } from "react";
import { Typography, Avatar } from "antd";
import "./CssNavbar.css";
import { Row, Col, Badge, Grid } from "antd";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import ModalCart from "components/cart/modalCart";
import { useNavigate, useLocation } from "react-router-dom";
import { CouterCartContext } from "store/CouterCartProvider";

const { Title } = Typography;
const { useBreakpoint } = Grid;
function NavBar() {
  const screens = useBreakpoint();
  const navigator = useNavigate();
  const location = useLocation();
  const { counter, openModal, reducerCloseModal } =
    useContext(CouterCartContext);
  const [visibleCart, setVisibleCart] = useState(false);
  useEffect(() => {
    setVisibleCart(openModal);
  }, [openModal]);
  return (
    <DivRoot>
      <Row justify="center">
        <Col id="root" span={22}>
          <Row align="middle">
            <Col xl={19} lg={18} sm={18} xs={19}>
              <Row align="middle">
                <Col xl={1} lg={2} sm={3} xs={4}>
                  <Avatar
                    onClick={() => navigator("/")}
                    style={{ cursor: "pointer" }}
                    size={36}
                    src="https://rb.gy/vq26cb"
                  ></Avatar>
                </Col>
                <Col xl={23} xs={19}>
                  <TitleLogo level={screens?.xs === true ? 5 : 4}>
                    HUMNOI-FIRELOOK
                  </TitleLogo>
                </Col>
              </Row>
            </Col>
            <Col
              id="screen-mobile"
              span={5}
              style={{ display: screens?.xs === true ? "" : "none" }}
            >
              <Row gutter={16} justify="end" align="middle">
                <Col
                  xl={14}
                  style={{
                    textAlign: "end",
                    marginTop: "0.2rem",
                    display:
                      location.pathname.includes("page-reciept") ||
                      location.pathname === "/confirm-order"
                        ? "none"
                        : "",
                  }}
                >
                  <Badge
                    size="small"
                    count={counter}
                    style={{ padding: "px 0px 5px 0px" }}
                  >
                    <FontAwesomeIcon
                      onClick={() => setVisibleCart(true)}
                      color="white"
                      style={{ fontSize: "16px", cursor: "pointer" }}
                      icon={faShoppingBasket}
                    />
                  </Badge>
                </Col>
                <Col xl={10}>
                  {/* <FontAwesomeIcon
                    color="white"
                    style={{ fontSize: "16px" }}
                    icon={faUser}
                  /> */}
                </Col>
              </Row>
            </Col>
            <Col
              id="screen-not-mobile"
              xl={5}
              lg={6}
              sm={6}
              style={{ display: screens?.xs === true ? "none" : "" }}
            >
              <Row gutter={32} justify="end" align="middle">
                <Col
                  xl={14}
                  style={{
                    textAlign: "end",
                    marginTop: "0.5rem",
                    display:
                      location.pathname.includes("page-reciept") ||
                      location.pathname === "/confirm-order"
                        ? "none"
                        : "",
                  }}
                >
                  <Badge count={counter} style={{ padding: "1px 0px 5px 0px" }}>
                    <FontAwesomeIcon
                      onClick={() => setVisibleCart(true)}
                      color="white"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      icon={faShoppingBasket}
                    />
                  </Badge>
                </Col>
                <Col xl={10}>
                  {/* <ButtonLogin>เข้าสู่ระบบ</ButtonLogin> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalCart
        visible={visibleCart}
        onClose={() => {
          setVisibleCart(false);
          reducerCloseModal();
        }}
      />
    </DivRoot>
  );
}

export default NavBar;
const DivRoot = styled.div`
  background: #12143e;
  padding: 10px;
`;
// const ButtonLogin = styled.button`
//   width: 100%;
//   border-radius: 5px;
//   color: #f48b24;
//   border: 1px solid #ffffff;
//   padding: 2px 10px 2px 10px;
//   line-height: 1.4rem;
//   background: transparent;
//   font-size: 14px;
//   font-weight: 600;
//   hight: 10px;
// `;
const TitleLogo = styled(Title)`
  color: #ee7c31 !important;
  font-weight: 700 !important;
  margin-bottom: unset !important;
`;
