import "./App.css";
import { Layout, Row, Col } from "antd";
import {
  PageHome,
  PageAllProductByCat,
  PageAllProduct,
  PageHomeProduct,
  PageConfirmOrder,
  PageReciept,
  PageproductBoxDetail,
} from "pages";
import Navbar from "components/control-pages/navbar/Navbar";
import { Routes, Route } from "react-router-dom";
const { Content } = Layout;

function App() {
  return (
    <div className="App">
      <Layout className="layout">
        <Navbar />
        <Content style={{ backgroundColor: "#ffff" }}>
          <Row style={{ marginTop: "1rem" }} justify="center">
            <Col xxl={18} lg={22} md={22} xs={22}>
              <Routes>
                <Route exact path="/" element={<PageHome />} />
                <Route
                  path="/product/:header/category/:catId"
                  element={<PageHomeProduct />}
                />
                 <Route
                  path="/all/:header/categoryId/:catId"
                  element={<PageAllProductByCat />}
                />
                <Route path="/all" element={<PageAllProduct />} />
                <Route path="/detail" element={<PageproductBoxDetail />} />
                <Route path="/confirm-order" element={<PageConfirmOrder />} />
                <Route path="/page-reciept" element={<PageReciept />} />
              </Routes>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
}

export default App;
