import React, { useRef, useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Row, Col } from "antd";
import styled from "styled-components";
import { URL_PATH } from "services/host";
import ImageDefault from "images/no-image.png";
import Avatar from "antd/lib/avatar/avatar";

function ImagesSlide(props) {
  const { defaultImage, images, onClickImage } = props;
  const [currentImage, setCurrentImage] = useState(defaultImage);
  const refSlide = useRef(null);
  const items = images?.map((item, index) => (
    <div
      style={{
        background: "white",
        padding: "10px",
        margin: "5px",
        borderRadius: "10px",
      }}
    >
      <ImageSlide
        key={index}
        alt={index}
        src={item ? `${URL_PATH}${item}` : ImageDefault}
        onClick={() => setCurrentImage(`${item}`)}
      />
    </div>
  ));
  const responsive = {
    0: { items: 3 },
    568: { items: 3 },
    1024: { items: 3 },
  };
  useEffect(() => {
    setCurrentImage(defaultImage);
  }, [defaultImage]);
  return (
    <div>
      <Row gutter={[16, 4]} justify="center">
        <Col span={20}>
          <div
            style={{
              background: "white",
              padding: "1rem",
              borderRadius: "10px",
            }}
          >
            <ImageCurrently
              style={{ cursor: onClickImage ? "pointer" : "" }}
              onClick={onClickImage ?? null}
              shape="square"
              src={currentImage ? `${URL_PATH}${currentImage}` : ImageDefault}
            />
          </div>
        </Col>
        <Col span={22}>
          <AliceCarousel
            dotsDisabled={true}
            buttonsDisabled={true}
            responsive={responsive}
            items={items}
            ref={refSlide}
            disableButtonsControls
          ></AliceCarousel>
        </Col>
      </Row>
    </div>
  );
}

export default ImagesSlide;
const ImageCurrently = styled(Avatar)`
  border-radius: 10px;
  width: 100%;
  min-height: 20vh;
  height: 100%;
`;
const ImageSlide = styled.img`
  cursor: pointer;
  display: inline-block;
  border-radius: 20px;
  width: 100%;
  height: auto;
`;
