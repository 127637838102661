import React, { useEffect, useContext } from "react";
import { Modal, Row, Col, Rate } from "antd";
import styled from "styled-components";
import { LabelPurple } from "components/Labels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  TextDarkGrey,
  TitleDarkPurple,
} from "components/styled-component/TextStyle";
import { InputAmount } from "components/Inputs";
import ButtonAddToCart from "components/buttons/ButtonAddToCart";
import { PriceNumber, UpdateCart } from "functions/index";
import { CouterCartContext } from "store/CouterCartProvider";
import { RenderImage } from "functions";

function ModalProductFreeBase(props) {
  const { visible, onHiding, item } = props;
  const { addProduct, setProduct, product } = useContext(CouterCartContext);
  const productData = item?.product_lists[0] ?? {};

  const onValueUpdate = (value) => {
    const result = {
      ...product,
      amount: value,
    };
    setProduct(result);
  };
  const onClickAddToCart = () => {
    UpdateCart(product, "products", callBackSuccess);
  };
  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    onCancle();
  };
  const onCancle = () => {
    setProduct({
      amount: 1,
      product_list_id: "",
    });
    onHiding();
  };
  useEffect(() => {
    setProduct({ ...product, product_list_id: productData.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData.id]);
  return (
    <ModalStyled
      width={600}
      footer={null}
      visible={visible}
      onCancel={onCancle}
      closeIcon={<FontAwesomeIcon icon={faTimesCircle} />}
      title="รายละเอียดสินค้า"
    >
      {item ? (
        <div>
          <Row justify="space-between">
            <Col xl={10} sm={10} xs={24}>
              <ImageStyled
                alt="modal-freebase"
                src={RenderImage(item?.product_cover_images?.path)}
              />
            </Col>
            <Col xl={12} sm={12} xs={24}>
              <Row>
                <Col md={24}>
                  <TitleDarkPurple level={5}>
                    {productData.name}
                  </TitleDarkPurple>
                </Col>
                <Col md={24}>
                  <TextDarkGrey type="14px">{productData.smell}</TextDarkGrey>
                </Col>
                <Col md={24} style={{ marginTop: "1rem" }}>
                  <TextDarkGrey type="12px">ระดับความหวาน</TextDarkGrey>
                  <RateOrange
                    disabled
                    defaultValue={productData.sweet}
                    count={10}
                  />
                </Col>
                <Col
                  md={24}
                  style={{ marginTop: "0.2rem", marginBottom: "1rem" }}
                >
                  <TextDarkGrey type="12px">ระดับความเย็น</TextDarkGrey>
                  <RatePurple
                    disabled
                    defaultValue={productData.cool}
                    count={10}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="space-between" align="bottom">
            <Col xl={10} sm={10} xs={24}>
              <LabelPurple>
                <DivTextButtom>
                  <SpanText>{`ราคาสินค้า (บาท)`}</SpanText>
                  <SpanPrice>{PriceNumber(item.price_show)}</SpanPrice>
                </DivTextButtom>
              </LabelPurple>
            </Col>
            <Col xl={13} sm={13} xs={24}>
              <Row gutter={[16, 4]}>
                <Col span={24}>
                  <SpanText>{`ระบุจำนวนสินค้า`}</SpanText>
                </Col>
                <Col xl={12} sm={12} xs={12}>
                  <InputAmount onValueUpdate={onValueUpdate} />
                </Col>
                <Col xl={12} sm={12} xs={12}>
                  <ButtonAddToCart size="14px" onClick={onClickAddToCart} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : null}
    </ModalStyled>
  );
}

export default ModalProductFreeBase;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
  }
  .ant-modal-body {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 20px !important;
  }
`;
const RateOrange = styled(Rate)`
  color: #f59012;
  font-size: 12px;
  display: inherit;
`;
const RatePurple = styled(Rate)`
  color: #4a67fa;
  font-size: 12px;
  display: inherit;
`;
const SpanText = styled.span`
  font-size: 12px;
`;
const SpanPrice = styled.strong`
  font-size: 16px;
`;
const ImageStyled = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
`;
const DivTextButtom = styled.div`
  display: flex;
  justify-content: space-between;
`;
