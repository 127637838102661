import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import styled from "styled-components";
import { AXIOS } from "services/host";
import { API_BANNER } from "services/apis";
import { URL_PATH } from "services/host";
export default function Banners() {
  const [imageBanner,setImageBanner] = useState([])

  const onFetchData = () => {
    AXIOS({
      method: "get",
      url: API_BANNER,
    })
      .then((res) => {
        const respondData = res.data.data;
        setImageBanner(respondData)
      })
      .catch((err) => {});
  };

  useEffect(onFetchData, []);
  return (
    <div>
      {/* <div style={{ marginBottom: "1rem" }}>
        <ImageBanner alt="" src="https://3c5.com/vEVNp" />
      </div> */}
      <div>
        <Carousel autoplay>
          {imageBanner.map((item) => (
            <ImageSlide key={item.id} alt="" src={`${URL_PATH}${item.img_path}`} />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

const ImageSlide = styled.img`
  width: 100%;
  height: 45vh;
  border-radius: 5px;
  @media (max-width: 600px) {
    height: 100px;
  }
  @media (max-width: 768px) {
    height: 200px;
  }
`;
// const ImageBanner = styled.img`
//   width: 100%;
//   height: 300px;
//   @media (max-width: 600px) {
//     height: 150px;
//   }
//   @media (max-width: 768px) {
//     height: 200px;
//   }
// `;
