import React, { useCallback, useState, useRef, useMemo } from "react";
import { Row, Col, Skeleton } from "antd";
import CardProduct from "components/products/cardProduct";
import CardProductBox from "components/products/cardProductBox";
import { UseAllProduct } from "functions";
import { ResultEmpty } from "components/control-pages/resultPage";
import { useParams, useLocation } from "react-router-dom";

export default function ShowProductByCatagory(props) {
  const observer = useRef();
  const { header, catId } = useParams();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const { products, hasMore, loading, totalRow } = UseAllProduct(
    pageNumber,
    catId,
    props.keyword
  );
  useMemo(() => {
    setPageNumber(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {products.length !== 0 && products[0] !== "500" ? (
            <Row
              gutter={[16, 16]}
              style={{
                backgroundColor: "#FAFAFA",
                padding: "1.1rem 0.7rem 1.1rem 0.7rem",
                borderRadius: 10,
              }}
            >
              {products?.map((item, index) => {
                if (products.length === index + 1) {
                  return (
                    <Col
                      ref={lastProductElementRef}
                      key={index}
                      xl={6}
                      lg={8}
                      sm={12}
                      xs={12}
                    >
                      {header.includes("กล่อง") ? (
                        <CardProductBox data={item} typeModal={header} />
                      ) : (
                        <CardProduct data={item} typeModal={header} />
                      )}
                    </Col>
                  );
                } else {
                  return (
                    <Col key={index} xl={6} lg={8} sm={12} xs={12}>
                      {header.includes("กล่อง") ? (
                        <CardProductBox data={item} typeModal={header} />
                      ) : (
                        <CardProduct data={item} typeModal={header} />
                      )}
                    </Col>
                  );
                }
              })}
            </Row>
          ) : null}
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              {products[0] === "500" ? (
                <ResultEmpty />
              ) : hasMore || products[0] === undefined ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
