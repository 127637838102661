import React, { useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Badge } from "antd";
import {
  TitleLightPurple,
  TextLightGrey,
} from "components/styled-component/TextStyle";
import { PriceNumber } from "functions";
// import { URL_PATH } from "services/host";
// import ImageDefault from "images/no-image.png";
// import { CouterCartContext } from "store/CouterCartProvider";

function BoxesItemFreeSaltNic() {
  // const { productSet, setCustomSet } = useContext(CouterCartContext);
  // const [currentImage, setCurrentImage] = useState("");
  useEffect(() => {
    // const img = data[0]?.product_lists[0]?.product_list_images.path
    //   ? `${data[0]?.product_lists[0].product_list_images.path}`
    //   : ImageDefault;
    // setCurrentImage(img);
  }, []);

  return (
    <DivRoot>
      <Badge.Ribbon placement="end" text="สินค้าแถมฟรี" color="green">
        <Row gutter={[16, 16]}>
          <Col xl={6} sm={6} xs={6}>
            {/* <Image alt="boxitem" src={`${URL_PATH}${currentImage}`} /> */}
            <Image
              alt="boxitem"
              src={`https://www.egothai.com/image/cache/catalog/ejuice/Coke_03_1-1000x1000.jpg`}
            />
          </Col>
          <Col xl={18} sm={18} xs={18}>
            <Row>
              <Col span={24}>
                <TitleLightPurple style={{ fontSize: "14px" }}>
                  นำ้ยาซอลนิค
                </TitleLightPurple>
                <TextQty>{`จำนวน : 1`}</TextQty>
                <DivPrice>
                  <TextPrice>{PriceNumber(0)}</TextPrice>
                  <TextLightGrey
                    style={{
                      marginRight: "10px",
                    }}
                    type="14px"
                    theme="line-through"
                  >
                    {PriceNumber(200)}
                  </TextLightGrey>
                  <TextLightGrey type="12px">ราคาสินค้า (บาท)</TextLightGrey>
                </DivPrice>
              </Col>
            </Row>
          </Col>
        </Row>
      </Badge.Ribbon>
    </DivRoot>
  );
}

export default BoxesItemFreeSaltNic;
const DivRoot = styled.div`
  padding: 10px;
  border: 1px dashed #e5e5e5;
  height: 100%;
  border-radius: 5px;
`;
const TextPrice = styled.span`
  color: #4a67fa;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`;
const TextQty = styled.p`
  margin-bottom: 0.5rem;
  color: #000000;
  font-size: 14px;
`;
const DivPrice = styled.div`
  align-items: center;
`;
const Image = styled.img`
  height: auto;
  width: 100%;
  border-radius: 5px;
`;
