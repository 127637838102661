import React from "react";
import styled from "styled-components";
import { Descriptions, Alert, Avatar } from "antd";
import { URL_PATH } from "services/host";
import { PriceNumber } from "functions/index";

function SuccessPayment(props) {
  const {
    code,
    pay_amount,
    payment_date,
    payment_slip_image,
    status_id,
    tracking_code,
  } = props.data;
  return (
    <div>
      <DivRoot>
        <DivHeder>
          <Alert
            message="แจ้งชำระเงินสำเร็จ"
            description="กรุณาแคปหน้าจอหน้านี้ แจ้งเจ้าหน้าที่ทาง Line"
            type="success"
            showIcon
          />
        </DivHeder>
        <DivDes>
          <Descriptions title="รายละเอียดการชำระเงิน" column={1}>
            <Descriptions.Item label="หมายเลขคำสั่งซื้อ ">
              {code}
            </Descriptions.Item>
            <Descriptions.Item label="วันที่ชำระเงิน">
              {new Date(payment_date)?.toLocaleDateString()}
            </Descriptions.Item>

            <Descriptions.Item label="จำนวนเงิน">
              {PriceNumber(pay_amount)}
            </Descriptions.Item>
            {status_id === 5 ? (
              <Descriptions.Item label="เลขพัสดุ">
                {tracking_code}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label="หลักฐานการชำระเงิน"></Descriptions.Item>
          </Descriptions>
        </DivDes>
        <Avatar
          shape="square"
          size={200}
          src={`${URL_PATH}${payment_slip_image}`}
        />
      </DivRoot>
    </div>
  );
}

export default SuccessPayment;
const DivHeder = styled.div`
  margin-bottom: 1rem;
`;
const DivRoot = styled.div`
height: 100%
  border-radius: 10px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: #f6f6f6;
  border-radius: 10px

`;

const DivDes = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
