import React from "react";
import { Empty } from "antd";
import styled from "styled-components";
export default function ResultEmpty() {
  return (
    <div>
      <EmptyStyle
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="ไม่พบรายการสินค้า"
      />
    </div>
  );
}

const EmptyStyle = styled(Empty)`
  border-radius: 10px;
  border: 1px solid #f0f5ff;
  padding: 3rem;
`;
