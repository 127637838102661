import React, { useRef, useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import styled from "styled-components";
import { Row, Col, Grid, Skeleton } from "antd";
import CardProduct from "components/products/cardProduct";
import CardProductBox from "components/products/cardProductBox";
import { AXIOS } from "services/host";
import ArrowLeft from "images/left-arrow.png";
import ArrowRight from "images/right-arrow.png";
import { API_GET_PRODUCT } from "services/apis";
import { ResultEmpty } from "components/control-pages/resultPage";

const { useBreakpoint } = Grid;

function ImagesSlide(props) {
  const refSlide = useRef(null);
  const screens = useBreakpoint();
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState(false);
  const [disabledPrevButton, setDisabledPrevButton] = useState(true);
  const qtyItem =
    screens.xxl === true
      ? 4
      : screens.xl === true || screens.md === true || screens.lg === true
      ? 3
      : screens.sm === true
      ? 2
      : screens.xs === true
      ? 2
      : null;
  useEffect(() => {
    if (qtyItem) {
      const fetchData = () => {
        AXIOS({
          url: API_GET_PRODUCT,
          method: "get",
          params: {
            page: 1,
            category_id : props.cateId
          },
        })
          .then((res) => {
            //เอาไปใช้ในเมนู
            const result = res.data.data;
            const rows = result.rows;
            const totalRows = result.total_rows;
            const newData = Array(qtyItem)
              .fill()
              .map((item, i) => rows[i]);
            const removeUndefied = newData.filter((item) => item !== undefined);
            setData(rows);
            setTotalRows(totalRows);
            setcurrentIndex(qtyItem - 1);
            setItems(removeUndefied);
            setLoading(false);
            setError(false);
          })
          .catch((err) => {
            setError(true);
            setLoading(false);
          });
      };
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyItem]);

  const onSlidePrev = () => {
    let prevIndex = currentIndex - qtyItem;
    if (prevIndex > 0) {
      const removeLastItem = items.filter((item, index) => index !== 2);
      const newItems = [data[prevIndex], ...removeLastItem];
      setItems([...newItems]);
      setcurrentIndex(currentIndex - 1);
      setDisabledNextButton(false);
    } else {
      setcurrentIndex(0);
      setDisabledPrevButton(true);
    }
  };
  const onSlideNext = () => {
    let nextIndex = currentIndex + 1;
    if (nextIndex < totalRows) {
      const removeFirstItem = items.filter((item, index) => index !== 0);
      const newItems = [...removeFirstItem, data[nextIndex]];
      setItems([...newItems]);
      setcurrentIndex(nextIndex);
      setDisabledPrevButton(false);
    } else {
      setDisabledNextButton(true);
      setcurrentIndex(totalRows - 1);
    }
  };
  return (
    <div>
      {loading === true ? (
        <Row align="middle" justify="center">
          <Col span={22}>
            <Skeleton active paragraph={{ rows: 2 }} />
          </Col>
        </Row>
      ) : error === false ? (
        <Row align="middle" justify="center">
          <Col xl={1} xs={2}>
            <IconArrow
              style={{
                cursor: disabledPrevButton === true ? "not-allowed" : "pointer",
              }}
              onClick={disabledPrevButton === true ? null : onSlidePrev}
              alt="left"
              src={ArrowLeft}
            />
          </Col>
          <Col xl={22} xs={20}>
            <AliceCarousel
              ref={refSlide}
              disableDotsControls
              disableButtonsControls
              fadeOutAnimation={true}
            >
              <Row gutter={[16, 16]}>
                {items.map((item, index) => (
                  <Col key={index} xxl={6} xl={8} lg={8} sm={12} xs={12}>
                    <DivSlide>
                      {props?.header.includes("กล่อง") ||
                      props?.header.includes("มอท") ||
                      props?.header.includes("พอต") ? (
                        <CardProductBox data={item} typeModal={props.header} />
                      ) : (
                        <CardProduct data={item} typeModal={props.header} />
                      )}
                    </DivSlide>
                  </Col>
                ))}
              </Row>
            </AliceCarousel>
          </Col>
          <Col xl={1} xs={2} style={{ textAlign: "end" }}>
            <IconArrow
              style={{
                cursor: disabledNextButton === true ? "not-allowed" : "pointer",
              }}
              onClick={disabledNextButton === true ? null : onSlideNext}
              alt="left"
              src={ArrowRight}
            />
          </Col>
        </Row>
      ) : (
        <ResultEmpty/>
      )}
    </div>
  );
}

export default ImagesSlide;
const IconArrow = styled.img`
  width: 1.3rem;
  height: 1.3rem;
`;
const DivSlide = styled.div`
  border-radius: 20px;
`;
