import React, { useEffect, useState, useContext } from "react";
import ImagesSlide from "components/productBoxes/ImagesSlide";
import { Row, Col, Space, Modal, Button } from "antd";
import styled from "styled-components";
import { PriceNumber, UpdateCart } from "functions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  TitleDarkPurple,
  TitleOrange,
  TextLightGrey,
  TitleDarkGrey,
} from "components/styled-component/TextStyle";
import { InputAmount } from "components/Inputs";
import ButtonAddToCart from "components/buttons/ButtonAddToCart";
import { ButtonOptions } from "components/buttons";
import { CouterCartContext } from "store/CouterCartProvider";
import { useNavigate } from "react-router-dom";

function ModalProductSaltNic(props) {
  const { visible, onHiding, item, path } = props;
  const data = item;
  const { addProduct, setProduct, product } = useContext(CouterCartContext);

  const navigate = useNavigate();

  const [currentImage, setCurrentImage] = useState("");
  const setMarginTop = (size) => {
    return { marginTop: size, marginBottom: size };
  };
  const onValueUpdate = (value) => {
    const result = {
      ...product,
      amount: value,
    };
    setProduct(result);
  };
  const onClickAddToCart = () => {
    if (!product.product_list_id) {
      Modal.warning({
        title: "แจ้งเตือน!",
        content: "กรุณาระบุสีของสินค้า",
        okText: "ตกลง",
      });
    } else {
      UpdateCart(product, "products", callBackSuccess);
    }
  };
  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    onCancle();
  };
  const onClickColor = (event) => {
    const result = {
      ...product,
      product_list_id: event.id,
    };
    const imgItem = event.product_list_images[0]?.path;
    setProduct(result);
    setCurrentImage(imgItem);
  };
  useEffect(() => {
    setCurrentImage(data?.product_lists[0]?.product_list_images[0]?.path);
  }, [data]);
  const onCancle = () => {
    setProduct({
      amount: 1,
      product_list_id: "",
    });
    onHiding();
  };
  return (
    <ModalStyled
      width={700}
      footer={null}
      visible={visible}
      onCancel={onCancle}
      closeIcon={<FontAwesomeIcon icon={faTimesCircle} />}
      title="รายละเอียดสินค้า"
    >
      {data ? (
        <div>
          <Row justify="space-around" gutter={[16, 16]}>
            <Col xl={13} lg={12} sm={12} xs={24}>
              <ImagesSlide
                onClickImage={() => navigate(path)}
                defaultImage={currentImage}
                images={data.product_lists.map(
                  (img) => img.product_list_images[0].path
                )}
              />
            </Col>
            <Col xl={11} lg={12} sm={12} xs={24}>
              <div style={{ cursor: "pointer" }} onClick={() => navigate(path)}>
                <TitleDarkPurple level={4}>{data.name}</TitleDarkPurple>
              </div>
              <DivFlex>
                <TitleOrange level={5}>
                  {PriceNumber(data.price_show)}
                </TitleOrange>
                <TextLightGrey
                  style={{ marginLeft: "10px", marginTop: "7px" }}
                  type="12px"
                >
                  ราคาสินค้า (บาท)
                </TextLightGrey>
              </DivFlex>
              <Button key="back" type="danger" onClick={() => navigate(path)}>
                เซ็ตพร้อมสูบ
              </Button>
              <div style={setMarginTop("1rem")}>
                <TitleDarkGrey style={{ fontSize: "14px" }}>
                  ระบุสีของสินค้า
                </TitleDarkGrey>
                <Space size={[8, 16]} wrap style={{ marginTop: "1rem" }}>
                  {data?.product_lists?.map((event, index) => {
                    return (
                      <ButtonOptions
                        size="small"
                        key={index}
                        onClick={() => onClickColor(event)}
                      >
                        {event.color}
                      </ButtonOptions>
                    );
                  })}
                </Space>
              </div>
              <div>
                <TitleDarkGrey style={{ fontSize: "14px", marginTop: "3vh" }}>
                  ระบุจำนวนสินค้า
                </TitleDarkGrey>
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col xl={12} sm={12} xs={12}>
                    <InputAmount onValueUpdate={onValueUpdate} />
                  </Col>
                  <Col xl={12} sm={12} xs={12}>
                    <ButtonAddToCart
                      style={{ height: "32px" }}
                      size="14px"
                      onClick={onClickAddToCart}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </ModalStyled>
  );
}

export default ModalProductSaltNic;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
  }
  .ant-modal-body {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 20px !important;
  }
`;
const DivFlex = styled.div`
  display: flex;
`;
