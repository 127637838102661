import React, { useEffect, useState, useContext } from "react";
import { Modal, Row, Col, Divider, Tag, Result, message } from "antd";
import { LabelHeaderModal } from "components/Labels";
import ImageFail from "images/shopping-fail.png";
import {
  faShoppingBasket,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterCart from "components/cart/footerCart";
import BoxesItem from "components/cart/BoxesItem";
import { AXIOS } from "services/host";
import { API_CART } from "services/apis";
import { CouterCartContext } from "store/CouterCartProvider";
import ItemProduct from "./ItemProduct";

function ModalCart(props) {
  const { visible, onClose } = props;
  const [products, setProducts] = useState([]);
  const [dataModalState, setDataModalState] = useState([]);
  const [currentTotal, setCurrentTotal] = useState([]);
  const { reducerSetCurrentCart, reducerSetCouterCart } =
    useContext(CouterCartContext);
  useEffect(() => {
    if (visible === true) {
      const resultGetCart =
        JSON.parse(localStorage.getItem("resultGetCart")) ?? [];
      const mergeProduct =
        JSON.parse(localStorage.getItem("mergeProduct")) ?? [];
      const getTotal = resultGetCart.map((item) => item.net_price);
      const sum = getTotal.reduce((partialSum, a) => partialSum + a, 0);
      setCurrentTotal(sum);
      setProducts([...resultGetCart]);
      setDataModalState(mergeProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    reducerSetCurrentCart(dataModalState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModalState]);
  const onValueUpdate = (value, index) => {
    const tmp = products;
    const getItem = tmp[index];
    getItem.amount = value;
    tmp[index] = getItem;
    const toCallValue = tmp.map((item) => item.price * item.amount);
    const sum = toCallValue.reduce((partialSum, a) => partialSum + a, 0);
    setCurrentTotal(sum);
    setProducts(tmp);
    /// update in localStorage
    const tmpStore = dataModalState;
    const getItemStore = tmpStore[index];
    getItemStore.amount = value;
    tmpStore[index] = getItemStore;
    setDataModalState([...tmpStore]);
    const couterCart = tmpStore.reduce(
      (partialSum, value) => partialSum + value.amount,
      0
    );
    reducerSetCouterCart(couterCart);
    //  update couter cart in store
  };
  const arrBox = (item) => {
    if (item.product_type !== "normal") {
      const arr = [];
      const objArray = Object.entries(item.product_set);
      objArray.forEach(([key, value]) => {
        if (key === item.product_type || key === "atom" || key === "cotton") {
          if (value && value?.product_list?.id > 0) {
            arr.push({
              type: key,
              value: value,
              isFree: key === "cotton" && item?.free_cotton,
            });
          }
        } else if (key === "charger" && value) {
          const tmp = value ?? [];
          tmp.forEach((itemCharge) => {
            arr.push({
              type: key,
              value: itemCharge,
            });
          });
        } else if (value && value?.product_list.id > 0) {
          arr.push({
            type: key,
            value: value,
          });
        }
      });
      return arr;
    }
  };

  const postData = (mergeProduct) => {
    const data = {
      products: mergeProduct.filter((item) => item.product_type === "products"),
      product_set: mergeProduct.filter(
        (item) => item.product_type === "product_set"
      ),
    };
    AXIOS({
      method: "post",
      url: API_CART,
      data: data,
    })
      .then((res) => {
        const respondData = res.data.data;
        if (respondData) {
          localStorage.setItem(`resultGetCart`, JSON.stringify(respondData));
          localStorage.setItem(`localProduct`, JSON.stringify(data));
          localStorage.setItem(`mergeProduct`, JSON.stringify(mergeProduct));
          setDataModalState([...mergeProduct]);
          //อัพเดตผลรวมปัจจุบัน
          const getTotal = respondData.map((item) => item.amount * item.price);
          const sum = getTotal.reduce((partialSum, a) => partialSum + a, 0);
          const getAmount = respondData.map((item) => item.amount);
          const sumCouterCart = getAmount.reduce(
            (partialSum, a) => partialSum + a,
            0
          );
          setProducts([...respondData]);
          reducerSetCouterCart(sumCouterCart);
          setCurrentTotal(sum);
        } else {
          //กรณีลบสินค้าหมดตะกร้า
          setProducts([]);
          reducerSetCouterCart(0);
          localStorage.clear();
          setCurrentTotal(0);
        }
      })
      .catch((err) => {
        message.error("เกิดข้อผิดพลาดไม่สามารถอัพเดตรายการสินค้าได้");
      });
  };
  const ondDeleteProduct = (index) => {
    // ลบค่าออกก่อน
    const mergeProduct = dataModalState;
    mergeProduct.splice(index, 1);
    return postData(mergeProduct);
    //จัดรูปแบบ
  };

  const onIdChange = (e, index) => {
    const tmp = dataModalState;
    const getItem = tmp[index];
    getItem.product_list_id = e;
    tmp[index] = getItem;
    setDataModalState([...tmp]);
  };
  const onIdBoxChange = (e, index, type) => {
    const tmp = dataModalState;
    const getItem = tmp[index];
    getItem[`${type}_id`] = e;
    tmp[index] = getItem;
    setDataModalState([...tmp]);
  };
  const onAtomModifyChange = (e, index) => {
    const tmp = dataModalState;
    const getItem = tmp[index];
    getItem.atom_modify = e.target.checked;
    tmp[index] = getItem;
    setDataModalState([...tmp]);
  };
  const onCloseModal = () => {
    postData(dataModalState);
    onClose();
  };
  return (
    <ModalStyled
      width={700}
      onCancel={onCloseModal}
      closeIcon={<FontAwesomeIcon icon={faTimesCircle} />}
      title={
        <LabelHeaderModal
          divider={false}
          text={"ตะกร้าสินค้า"}
          icon={faShoppingBasket}
        />
      }
      visible={visible}
      footer={
        <FooterCart
          onClose={() => onClose()}
          currentData={dataModalState}
          totalCart={currentTotal}
          disabled={products.length < 1}
        />
      }
    >
      {products.length === 0 ? (
        <ResultEmpty
          icon={<img height={60} width={60} alt="" src={ImageFail}></img>}
          subTitle="ไม่มีสินค้าในตะกร้า"
        />
      ) : (
        <Row gutter={[8, 8]}>
          {products.map((item, index) => (
            <Col span={24} key={index}>
              <ItemProduct
                index={index}
                item={item}
                ondDeleteProduct={ondDeleteProduct}
                onValueUpdate={onValueUpdate}
                onIdChange={onIdChange}
                dataModalState={dataModalState}
              />
              {item.product_type !== "normal" ? (
                <div style={{ marginTop: "1rem" }}>
                  <Tag style={{ borderRadius: "3px" }} color="purple">
                    สินค้าในเซ็ต
                  </Tag>
                  <Row justify="end" style={{ marginTop: "10px" }}>
                    <Col span={24}>
                      <Row gutter={[8, 8]}>
                        {arrBox(item).map((product, indexBox) => (
                          <Col lg={12} sm={12} xs={24} key={indexBox}>
                            <BoxesItem
                              item={product}
                              parent={index}
                              onIdBoxChange={onIdBoxChange}
                              onAtomModifyChange={onAtomModifyChange}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Divider style={{ margin: "15px 10px 10px 0px" }} />
            </Col>
          ))}
        </Row>
      )}
    </ModalStyled>
  );
}

export default ModalCart;
const ResultEmpty = styled(Result)`
  border-radius: 10px;
  border: 1px solid #f0f5ff;
  /* background: #fafafa; */
`;

const ModalStyled = styled(Modal)`
  .ant-modal-header {
    border-radius: 10px 10px 0px 0px;
  }
  .ant-modal-footer {
    background-color: #12143e;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
  .ant-modal-body {
    max-height: 60vh;
    overflow: auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 10px !important;
  }
`;
