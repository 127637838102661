import styled from "styled-components";
import { Typography } from "antd";
const { Text, Title } = Typography;

export const TitleDarkGrey = styled(Title)`
  color: #3a4047 !important;
`;
export const TitleDarkPurple = styled(Title)`
  font-weight: 600 !important;
  color: #12143e !important;
  line-height: 1.5 !important;
`;
export const TitleLightPurple = styled(Title)`
  font-weight: 600 !important;
  color: #5f2083 !important;
`;
export const TitleOrange = styled(Title)`
  font-weight: 600 !important;
  color: #f26440 !important;
`;
export const TitleRed = styled(Title)`
  font-weight: 600 !important;
  color: #d02c2f !important;
  @media (max-width: 600px) {
    font-size: 16px !important;
  }
`;
export const TextDarkGrey = styled(Text)`
  color: #3a4047;
  font-size: ${(props) => props.type || 14};
`;
export const TextLightGrey = styled(Text)`
  color: #bbbbbb;
  font-size: ${(props) => props.type || 14};
  text-decoration: ${(props) =>
    props.theme === "line-through" ? "line-through" : ""};
`;
export const TextDarkPurple = styled(Text)`
  color: #12143e;
  font-size: ${(props) => props.type || 14};
`;
export const TextOrange = styled(Text)`
  color: #f26440;
  font-size: ${(props) => props.type || 14};
`;
