import React, { useEffect, useState } from "react";
import {
  RecieptDetail,
  TransferDetail,
  SucessPayment,
} from "components/reciept";
import { TitleDarkPurple } from "components/styled-component/TextStyle";
import { Row, Col, Spin, message } from "antd";
import { AXIOS } from "services/host";
import { API_GET_RECIEPT } from "services/apis";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

function PageReciept() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  let location = useLocation();
  const ref_code = new URLSearchParams(location.search).get("reciept_id");
  const fetchData = () => {
    AXIOS({
      method: "get",
      url: API_GET_RECIEPT,
      params: {
        ref_code: ref_code,
      },
    })
      .then((res) => {
        const respondData = res.data.data;
        setData(respondData);
        setLoading(false);
      })
      .catch((err) => {
        message.error("เกิดข้อผิดพลาด");
        setLoading(false);
      });
  };
  useEffect(fetchData, [ref_code]);

  const callbackFetchData = () => {
    setLoading(true);
    fetchData();
  };
  return (
    <Row gutter={[32, 16]} justify="center" style={{ marginBottom: "2rem" }}>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Col span={20}>
          <Row
            gutter={[32, 16]}
            justify="center"
            style={{ marginBottom: "2rem" }}
          >
            <Col span={24} style={{ margin: "1rem 0px 0.5rem 0px" }}>
              <TitleDarkPurple level={4}>
                {`คำสั่งซื้อหมายเลข : ${ref_code} `}
              </TitleDarkPurple>
            </Col>
            <Col xl={12} lg={12} sm={22} xs={24}>
              <RecieptDetail data={data && data} />
            </Col>
            <Col xl={12} lg={12} sm={22} xs={24}>
              {!data?.payment_date ? (
                <TransferDetail
                  data={data && data}
                  fetchData={callbackFetchData}
                />
              ) : (
                <SucessPayment data={data && data} />
              )}
            </Col>
          </Row>
        </Col>
      </Spin>
    </Row>
  );
}

export default PageReciept;
