import React, { useState, useMemo, useContext, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Modal, Tag } from "antd";
import { TitleRed } from "components/styled-component/TextStyle";
import { LabelRecommend } from "components/Labels/index";
import { PriceNumber, UpdateCart } from "functions";
import BoxesItem from "./BoxesItem";
import BoxesItemFreeSaltNic from "./BoxesItemFreeSaltNic";
import { InputAmount } from "components/Inputs";
import ButtonAddToCart from "components/buttons/ButtonAddToCart";
import { CouterCartContext } from "store/CouterCartProvider";
function CustomBox() {
  const {
    addProduct,
    productDetail,
    productSet,
    setCustomSet,
    resetCustomsetBox,
  } = useContext(CouterCartContext);
  const data = productDetail.product_set;
  const { free_cotton, free_liquid_by_store, free_shipping } = productDetail;
  const [arrBoxItems, setArrBoxItems] = useState([]);
  const [isReset, setIsReset] = useState(false);
  useMemo(() => {
    if (productDetail.product_in_set.length > 0) {
      const tmpSelect = []
 Object.entries(data)
.filter((item) => {
  const key = item[0]
  const value = item[1]
 const isArr =  Array.isArray(value) 
  if(isArr){
    if(value.length > 0){
      return tmpSelect.push({type: key,item: value})
    }
  }
})
      // const tmpArr = [
      //   { type: data.set_type, item: data[data.set_type || "box"] },
      //   { type: "atom", item: data.atom },
      //   { type: "cotton", item: data.cotton },
      //   { type: "coil", item: data.coil },
      // ];
      // data?.charger?.forEach((item) => {
      //   tmpArr.push({ type: "charger", item: [item] });
      // });
      // const filterEmptyArray = tmpArr?.filter(
      //   (item) => item.item && item.item.length > 0
      // );
      // tmpSelect.unshift({ type: data.set_type, item: data[data.set_type || "box"] })
      setArrBoxItems([...tmpSelect]);
    }
  }, [data]);

  const onValueUpdate = (value) => {
    setCustomSet({ ...productSet, amount: value });
  };

  const checkDisabled = () => {
    switch (productSet.set_type) {
      case "box":
        if (productSet.box_id && productSet.atom_id) {
          return false;
        } else {
          return true;
        }
      case "mod":
        if (productSet.mod_id && productSet.atom_id) {
          return false;
        } else {
          return true;
        }
      case "pod":
        if (productSet.pod_id) {
          return false;
        } else {
          return true;
        }
      default:
        break;
    }
  };
  const onClickAddToCart = () => {
    if (checkDisabled()) {
      Modal.warning({
        title: "แจ้งเตือน!",
        content: "ระบุตัวเลือกสินค้าในชุดเซ็ตพร้อมสูบก่อนเลือกเข้าตะกร้า",
        okText: "ตกลง",
      });
    } else {
      setIsReset(false);
      UpdateCart(productSet, "product_set", callBackSuccess);
    }
  };
  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    resetCustomsetBox();
    setIsReset(true);
  };
  useEffect(() => {
    const { cotton, charger, coil } = data;
    const cotton_id =
      cotton && cotton.length > 0 ? cotton[0]?.product_lists[0].id : 0;
      const coil_id =
      coil && coil.length > 0 ? coil[0]?.product_lists[0].id : 0;
    const charger_id =
      charger && charger.map((item) => item?.product_lists[0]?.id);
    setCustomSet({
      ...productSet,
      set_type: data?.set_type,
      cotton_id,
      charger_id,
      coil_id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Row gutter={32}>
        <ColCustom xl={24}>
          <Row gutter={[16, 8]}>
            <Col xxl={2} xl={3} lg={3} sm={4} xs={7}>
              <LabelRecommend>สินค้าแนะนำ</LabelRecommend>
            </Col>
            <Col xxl={14} xl={13} lg={13} sm={10} xs={24}>
              <StongWhite>{data.name}</StongWhite>
            </Col>

            <Col xxl={8} xl={8} lg={8} sm={10} xs={24}>
              <Row gutter={[16, 16]}>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <InputAmount onValueUpdate={onValueUpdate} />
                </Col>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <ButtonAddToCart
                    style={{ height: "32px" }}
                    size="14px"
                    onClick={onClickAddToCart}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <TitleRed style={{ margin: "unset" }} level={4}>{`${PriceNumber(
                data.price
              )} บาท`}</TitleRed>
              <Tag color="cyan">สินค้านี้จัดส่งฟรี</Tag>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
            {arrBoxItems.map((item, index) => (
              <Col key={index} lg={8} sm={12} xs={24}>
                <BoxesItem
                  item={{
                    type: item.type,
                    data: item.item,
                  }}
                  isFree={{
                    free_cotton,
                    free_shipping,
                  }}
                  isReset={isReset}
                />
              </Col>
            ))}
            {free_liquid_by_store === true ? (
              <Col key="free-saltnic" lg={8} sm={12} xs={24}>
                <BoxesItemFreeSaltNic />
              </Col>
            ) : null}
          </Row>
        </ColCustom>
      </Row>
    </div>
  );
}

export default CustomBox;

const ColCustom = styled(Col)`
  margin-top: 0.5rem;
  border-radius: 10px;
  padding: 10px 0px 5px 0px;
`;

const StongWhite = styled.strong`
  color: #12143e;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;
