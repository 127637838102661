import React, { useEffect, useContext } from "react";
import { Modal, Row, Col, Typography } from "antd";
import styled from "styled-components";
import { LabelPurple } from "components/Labels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  TextDarkGrey,
  TitleDarkPurple,
} from "components/styled-component/TextStyle";
import { InputAmount } from "components/Inputs";
import ButtonAddToCart from "components/buttons/ButtonAddToCart";
import { PriceNumber, UpdateCart } from "functions/index";
import { CouterCartContext } from "store/CouterCartProvider";
import { RenderImage } from "functions";

const { Text } = Typography;
function ModalProductSaltNic(props) {
  const { visible, onHiding, item } = props;
  const { addProduct, setProduct, product } = useContext(CouterCartContext);
  const productData = item?.product_lists[0] ?? {};

  const onValueUpdate = (value) => {
    const result = {
      ...product,
      amount: value,
    };
    setProduct(result);
  };
  const onClickAddToCart = () => {
    UpdateCart(product, "products", callBackSuccess);
  };
  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    onCancle();
  };
  useEffect(() => {
    setProduct({ ...product, product_list_id: productData.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData.id]);
  const onCancle = () => {
    setProduct({
      amount: 1,
      product_list_id: "",
    });
    onHiding();
  };
  return (
    <ModalStyled
      width={700}
      footer={null}
      visible={visible}
      onCancel={onCancle}
      closeIcon={<FontAwesomeIcon icon={faTimesCircle} />}
      title="รายละเอียดสินค้า"
    >
      {item ? (
        <div>
          <Row justify="space-between">
            <Col xl={8} sm={10} xs={24}>
              <ImageStyled
                alt="modal-saltnic"
                src={RenderImage(item?.product_cover_images?.path)}

              />
            </Col>
            <Col xl={13} sm={12} xs={24}>
              <Row>
                <Col md={24}>
                  <TitleDarkPurple level={4}>
                    {productData.name}
                  </TitleDarkPurple>
                </Col>
                <Col md={24}>
                  <TextDarkGrey type="14px">{productData.smell}</TextDarkGrey>
                </Col>
                <ColumnCoil
                  lg={20}
                  sm={24}
                  xs={24}
                  style={{ marginTop: "1rem" }}
                >
                  <DivCoil color="#5F2083">คอยล์ 0.6</DivCoil>
                  <DividerCoil color="#5F2083" />
                  <TextCoil color="#5F2083">
                    {productData.coil_point_six}
                  </TextCoil>
                </ColumnCoil>
                <ColumnCoil
                  lg={20}
                  sm={24}
                  xs={24}
                  style={{ marginTop: "1rem" }}
                >
                  <DivCoil color="#4A67FA">คอยล์ 0.8</DivCoil>
                  <DividerCoil color="#4A67FA" />
                  <TextCoil color="#4A67FA">
                    {productData.coil_point_six}
                  </TextCoil>
                </ColumnCoil>
                <ColumnCoil
                  lg={20}
                  sm={24}
                  xs={24}
                  style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                >
                  <DivCoil color="#7B40F8">แทงคอ</DivCoil>
                  <DividerCoil color="#7B40F8" />
                  <TextCoil style={{ width: "155px" }} color="#7B40F8">
                    {productData.symptom}
                  </TextCoil>
                </ColumnCoil>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="space-between" align="bottom">
            <Col xl={8} sm={10} xs={24}>
              <LabelPurple>
                <DivTextButtom>
                  <SpanText>{`ราคาสินค้า (บาท)`}</SpanText>
                  <SpanPrice>{PriceNumber(item.price_show)}</SpanPrice>
                </DivTextButtom>
              </LabelPurple>
            </Col>
            <Col xl={13} sm={13} xs={24}>
              <Row gutter={[16, 4]}>
                <Col span={24}>
                  <SpanText>{`ระบุจำนวนสินค้า`}</SpanText>
                </Col>
                <Col xl={12} sm={12} xs={12}>
                  <InputAmount onValueUpdate={onValueUpdate} />
                </Col>
                <Col xl={12} sm={12} xs={12}>
                  <ButtonAddToCart type="14px" onClick={onClickAddToCart} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : null}
    </ModalStyled>
  );
}

export default ModalProductSaltNic;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
  }
  .ant-modal-body {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 20px !important;
  }
`;
const SpanText = styled.span`
  font-size: 12px;
`;
const SpanPrice = styled.strong`
  font-size: 16px;
`;
const ColumnCoil = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ImageStyled = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
`;
const DivTextButtom = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DividerCoil = styled.hr`
  width: 1.8rem;
  background-color: ${(props) => props.color};
`;
const TextCoil = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.color};
`;
const DivCoil = styled.div`
  background-color: ${(props) => props.color};
  width: 5rem;
  padding: 2px;
  border-radius: 7px;
  color: #ffff;
  font-weight: 500;
  text-align: center;
`;
