import React from "react";
import { Result, Row, Col, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

export default function ResultError() {
  return (
    <div>
      <Row align="middle" justify="center">
        <Col span={22}>
          <Result
            status="error"
            subTitle="เกิดข้อผิดพลาด ไม่สามารถเรียกข้อมูลได้"
            extra={
              <Button
                ghost
                onClick={() => window.location.reload()}
                type="primary"
                shape="round"
                icon={<ReloadOutlined />}
              >
                โหลดอีกครั้ง
              </Button>
            }
          />
        </Col>
      </Row>
    </div>
  );
}
