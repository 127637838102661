import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { ButtonDarkPurple } from "components/styled-component/ButtonStyled";
import { useNavigate } from "react-router-dom";

const ButtonAddToCart = () => {
  const navigator = useNavigate();
  return (
    <ButtonDarkPurple type={"14px"} onClick={() => navigator(-1)}>
      <FontAwesomeIcon
        style={{ marginRight: "10px" }}
        icon={faArrowAltCircleLeft}
      />
      ย้อนกลับ
    </ButtonDarkPurple>
  );
};

export default ButtonAddToCart;
