import React, { useState, useContext } from "react";
import { ButtonAddToCart } from "components/buttons";
import ModalProductFreeBase from "./modalProductFreeBase";
import ModalProductSaltNic from "./modalProductSaltNic";
import ModalProduct from "./modalProduct";
import ModalSelectBox from "./modalSelectBox";
import { Row, Col } from "antd";
import { Typography } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { UpdateCart } from "functions";
import { ButtonSet } from "components/styled-component/ButtonStyled";
import { PriceNumber } from "functions";
import { CouterCartContext } from "store/CouterCartProvider";
import { RenderImage } from "functions";

const { Paragraph } = Typography;
function CardProduct(props) {
  const { data, typeModal } = props;
  const { addProduct, setProduct } = useContext(CouterCartContext);
  const navigator = useNavigate();
  const [modalFreeBase, setModalFreeBase] = useState({
    visible: false,
    item: null,
  });
  const [modalSaltNic, setModalSaltNic] = useState({
    visible: false,
    item: null,
  });
  const [modalProduct, setModalProduct] = useState({
    visible: false,
    item: null,
  });
  const [isModalOpen, setIsModalOpen] = useState({
    visible: false,
    item: null,
  });
  const onClickDetail = ({ product_lists }) => {
    const { product_id } = product_lists[0];
    const header = typeModal.split(" :");
    switch (header[0]) {
      case "น้ำยา Sal Nic":
        return setModalSaltNic({ visible: true, item: data });
      case "น้ำยา Freebase":
        return setModalFreeBase({ visible: true, item: data });
      case "อะตอม":
        return setIsModalOpen({ visible: true, item: data });
      case "กล่อง":
        navigator(`/detail?v=${encodeURIComponent(product_id)}`);
        break;
      case "พอต":
        navigator(`/detail?v=${encodeURIComponent(product_id)}`);
        break;
      case "มอท":
        navigator(`/detail?v=${encodeURIComponent(product_id)}`);
        break;
      default:
        return setModalProduct({ visible: true, item: data });
    }
  };
  const onClickAddToCart = (item) => {
    const header = typeModal.split(" :");
    if (header[0] !== "อะตอม") {
      const product = {
        product_list_id: data && data.product_lists[0].id,
        amount: 1,
      };
      UpdateCart(product, "products", callBackSuccess);
    } else {
      setIsModalOpen({ visible: true, item });
    }
  };

  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    setProduct({
      amount: 1,
      product_list_id: "",
    });
  };
  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: "#ffff",
        borderRadius: 10,
      }}
    >
      <Row gutter={[8, 4]}>
        <ImageProduct
          onClick={() => onClickDetail(data)}
          alt="example"
          src={RenderImage(data?.product_cover_images?.path)}
        />

        <Col xl={24} sm={24} xs={24}>
          <TextProductName
            style={{ cursor: "pointer" }}
            onClick={() => onClickDetail(data)}
            ellipsis={{
              rows: 1,
              tooltip: [<TooltipText>{data?.name}</TooltipText>],
            }}
          >
            {data?.name}
          </TextProductName>
        </Col>
        <Col xl={24} sm={24} xs={24}>
          <TextPrice>{`${PriceNumber(data?.price_show)} THB`}</TextPrice>
        </Col>
        <Col xl={12} sm={24} xs={24}>
          <ButtonSet onClick={() => onClickDetail(data)}>รายละเอียด</ButtonSet>
        </Col>
        <Col xl={12} sm={24} xs={24}>
          <ButtonAddToCart onClick={() => onClickAddToCart(data)} />
        </Col>
      </Row>

      <ModalProductFreeBase
        visible={modalFreeBase.visible}
        item={modalFreeBase.item}
        onHiding={() => setModalFreeBase({ visible: false, item: null })}
      />
      <ModalProductSaltNic
        visible={modalSaltNic.visible}
        item={modalSaltNic.item}
        onHiding={() => setModalSaltNic({ visible: false, item: null })}
      />
      <ModalProduct
        visible={modalProduct.visible}
        item={modalProduct.item}
        onHiding={() => setModalProduct({ visible: false, item: null })}
      />
      <ModalSelectBox
        visible={isModalOpen.visible}
        item={isModalOpen.item}
        onHiding={() => setIsModalOpen({ visible: false, item: null })}
      />
    </div>
  );
}

export default CardProduct;
const ImageProduct = styled.img`
  cursor: pointer;
  margin: auto;
  width: 8rem;
  height: 9rem;
  @media (max-width: 600px) {
    height: 6rem;
  }
`;
const TooltipText = styled.span`
  font-size: 12px;
`;

const TextPrice = styled.span`
  color: #4a67fa;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;
const TextProductName = styled(Paragraph)`
  font-weight: 500;
  color: #12143e;
  font-size: 14px;
`;
