import React from "react";
import styled from "styled-components";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
export default function PageHeader() {
  const { parent, catId, name } = useParams();
  const navigate = useNavigate();
  return (
    <DivRoot>
      <Breadcrumb style={{ cursor: "pointer", color: "#5f2083" }}>
        <Breadcrumb.Item onClick={() => navigate(`/`)}>
          <HomeOutlined />
          <span>กลับหน้าหลัก</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate(`/all/${parent}/parent/${parent}`)}
        >
          <span>{parent}</span>
        </Breadcrumb.Item>
        {name !== parent ? (
          <Breadcrumb.Item
            onClick={() => navigate(`/all/${parent}/${catId}/${name}`)}
          >
            {name}
          </Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
    </DivRoot>
  );
}

const DivRoot = styled.div`
  padding: 1rem;
  border-radius: 5px;
  background: #fbfafe;
`;
