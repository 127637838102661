import React, { useState } from "react";
import styled from "styled-components";
import {
  Spin,
  Row,
  Col,
  Modal,
  Button,
  Upload,
  Form,
  InputNumber,
  message,
  DatePicker,
  TimePicker,
} from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { AXIOS } from "services/host";
import { API_UPLOAD_SLIP } from "services/apis";
import { URL_PATH } from "services/host";
import { TitleDarkPurple } from "components/styled-component/TextStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonOrange } from "components/styled-component/ButtonStyled";

const { confirm } = Modal;
function TransferDetail(props) {
  const { data, fetchData } = props;
  const bank = data.store_banks;
  const [loading, setLoading] = useState(false);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const showPromiseConfirm = (form) => {
    confirm({
      title: "ยืนยันการแจ้งโอนเงิน?",
      icon: <ExclamationCircleOutlined />,
      content: "หลังจากแจ้งโอนเงินแล้ว กรุณารอการตรวจสอบยอดชำระจากเจ้าหน้าที่",
      onOk: () => onsubmit(form),
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onCancel() {},
    });
  };
  const onsubmit = (form) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("ref_code", data.code);
    formData.append("pay_date", form.pay_date.format("YYYY-MM-DD"));
    formData.append("pay_time", form.pay_time.format("HH:mm:ss"));
    formData.append("pay_amount", form.pay_amount);
    formData.append("slip_image", form.slip_image[0].originFileObj);
    AXIOS({
      method: "post",
      url: API_UPLOAD_SLIP,
      data: formData,
    })
      .then((res) => {
        setLoading(false);
        message.success("แจ้งชำระเงินสำเร็จ!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        message.error("เกิดข้อผิดพลาด ลองใหม่อีกครั้ง");
      });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <div>
      <Spin spinning={loading} tip="กรุณารอสักครู่...">
        <DivRoot>
          <DivHeder>
            <FontAwesomeIcon
              style={{
                color: "#12143e",
                fontSize: "16px",
                marginRight: "10px",
              }}
              icon={faCreditCard}
            />
            <TitleDarkPurple level={5}>{"แจ้งโอนเงิน"}</TitleDarkPurple>
          </DivHeder>

          <DivBank>
            <Row align="middle" gutter={[16, 8]}>
              <Col xl={6} lg={6} sm={6}>
                <ImageStyle src={`${URL_PATH}${bank?.image_file}`} />
              </Col>
              <Col xl={18} lg={18} sm={18}>
                <Row>
                  <Col span={24}>
                    <p style={{ color: "#797979", marginBottom: "5px" }}>
                      {`โอนเงินเข้าบัญชีธนาคาร ${bank?.name} สาขา ${bank?.branch}`}
                    </p>
                  </Col>
                  <Col span={24}>
                    <p style={{ color: "#797979", marginBottom: "5px" }}>
                      {`เลขที่บัญชี : ${bank?.account_number}`}
                    </p>
                  </Col>
                  <Col span={24}>
                    <p style={{ color: "#797979", marginBottom: "5px" }}>
                      {`ชื่อบัญชี : ${bank?.owner_name}`}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </DivBank>
          <Form name="form" layout="vertical" onFinish={showPromiseConfirm}>
            <Row gutter={[8, 0]}>
              <Col sm={24} xs={24}>
                <Form.Item
                  name="slip_image"
                  label="แนบสลิป"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[{ required: true, message: "กรุณาแนบสลิป" }]}
                >
                  <Upload
                    accept="image/png, image/jpeg"
                    name="logo"
                    customRequest={dummyRequest}
                    listType="picture"
                    multiple={false}
                  >
                    <Button icon={<UploadOutlined />}>อัปโหลดสลิป</Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col xl={8} sm={8} xs={24}>
                <Form.Item
                  label="จำนวนเงินที่โอน"
                  name="pay_amount"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      required: true,
                      message: "กรุณาระบุจำนวนเงินที่โอน!",
                    },
                  ]}
                >
                  <InputNumber
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    formatter={(value) => `฿${value}`}
                    style={{ width: "100%" }}
                    type="tel"
                  />
                </Form.Item>
              </Col>
              <Col xl={8} sm={8} xs={24}>
                <Form.Item
                  label="วันที่โอน"
                  name="pay_date"
                  rules={[{ required: true, message: "กรุณาระบุวันที่!" }]}
                >
                  <DatePicker format="DD/MM/yyyy" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xl={8} sm={8} xs={24}>
                <Form.Item
                  label="เวลาโอน"
                  name="pay_time"
                  rules={[{ required: true, message: "กรุณาระบุวันที่!" }]}
                >
                  <TimePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={8}>
              <Col xl={8} sm={8} xs={24}>
                <ButtonOrange htmlType="submit">ยืนยันการชำระเงิน</ButtonOrange>
              </Col>
            </Row>
          </Form>
        </DivRoot>
      </Spin>
    </div>
  );
}

export default TransferDetail;
const DivHeder = styled.div`
  display: flex;
`;
const DivRoot = styled.div`
height: 100%
  border-radius: 10px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border: 1px solid #e5e5e5;
`;

const DivBank = styled.div`
  background: #f6f6f6;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
const ImageStyle = styled.img`
  width: 100px;
  height: 50px;
`;
