import React, { useContext } from "react";
import styled from "styled-components";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { CouterCartContext } from "store/CouterCartProvider";

export default function BreadcrumbsProductBox() {
  const { productDetail } = useContext(CouterCartContext);
  const navigate = useNavigate();
  return (
    <DivRoot>
      <Breadcrumb style={{ cursor: "pointer", color: "#5f2083" }}>
        <Breadcrumb.Item onClick={() => navigate(`/`)}>
          <HomeOutlined />
          <span>Home</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/all`)}>
          <span>สินค้าทั้งหมด</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{productDetail.name}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
    </DivRoot>
  );
}

const DivRoot = styled.div`
  padding: 1rem;
  border-radius: 5px;
  background: #fbfafe;
`;
