import React, { useRef, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Row, Col, Form, Select, Badge, Checkbox, Typography } from "antd";
import {
  TitleLightPurple,
  TextLightGrey,
} from "components/styled-component/TextStyle";
import { PriceNumber } from "functions";
import { URL_PATH } from "services/host";
import ImageDefault from "images/no-image.png";
import { CouterCartContext } from "store/CouterCartProvider";
import { renderNameType } from "functions/renderNameType";
const { Text } = Typography;
const { Option } = Select;
function BoxesItem(props) {
  const [form] = Form.useForm();
  const { item, isFree, isReset } = props;
  const { type, data } = item;
  const formData = useRef(null);
  const [colors, setColors] = useState([]);
  const { productSet, setCustomSet } = useContext(CouterCartContext);
  const [currentImage, setCurrentImage] = useState("");
  const [modelAtom, setModelAtom] = useState("");
  useEffect(() => {
    if (type === "box" || type === "pod" || type === "mod") {
      const objData = data[0];
      const objProduct = objData.product_lists;
      setColors(objProduct);
    }
    const img = data[0]?.product_lists[0]?.product_list_images.path
      ? `${data[0]?.product_lists[0].product_list_images.path}`
      : ImageDefault;
    setCurrentImage(img);
    setModelAtom(data[0]?.name);
  }, [type, data]);

  const onModelChange = (e) => {
    form.setFieldsValue({ color: "" });
    const objProduct = data.find((item) => item.id === e);
    setModelAtom(objProduct.name);
    setColors(objProduct.product_lists);
  };

  const onIdChange = (e) => {
    setCustomSet({ ...productSet, [`${type}_id`]: e });
    const arr = type === "atom" ? colors : data[0].product_lists;
    const found = arr.find((x) => x.id === e);
    setCurrentImage(found?.product_list_images?.path);
  };
  const onCheckBox = (e) => {
    setCustomSet({ ...productSet, atom_modify: e.target.checked });
  };
  useEffect(() => {
    if (isReset === true) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);
  return (
    <DivRoot>
      <Badge.Ribbon
        style={{
          display:
            isFree.free_cotton === true && type === "cotton" ? "" : "none",
        }}
        placement="start"
        text="สินค้าแถมฟรี"
        color="green"
      >
        <Row gutter={[16, 4]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <span style={{ fontSize: "12px" }}>{`ประเภท: ${renderNameType(
              item.type
            )}`}</span>
          </Col>
          <Col xl={6} sm={6} xs={6}>
            <Image alt="boxitem" src={`${URL_PATH}${currentImage}`} />
          </Col>
          <Col xl={18} sm={18} xs={18}>
            <Row>
              <Col span={24}>
                <TitleLightPurple style={{ fontSize: "14px" }}>
                  {modelAtom}
                </TitleLightPurple>
              </Col>
              <Col span={24}>
                <TextQty>{`จำนวน : ${data[0]?.qty}`}</TextQty>
                <DivPrice>
                  <TextPrice style={{ display: isFree === true ? "none" : "" }}>
                    {PriceNumber(data[0]?.price)}
                  </TextPrice>
                  <TextLightGrey
                    style={{
                      display: isFree === true ? "" : "none",
                      marginRight: "10px",
                    }}
                    type="14px"
                    theme="line-through"
                  >
                    {PriceNumber(data[0]?.price_show)}
                  </TextLightGrey>
                  <TextLightGrey type="12px">ราคาสินค้า (บาท)</TextLightGrey>
                </DivPrice>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col
            span={24}
            style={{
              display:
                type === "cotton" || type === "charger" || type === "battery"
                  ? "none"
                  : "",
            }}
          >
            <Form ref={formData} form={form} layout="vertical">
              <Row
                justify="center"
                gutter={[4, 4]}
                style={{ display: type && type === "atom" ? "" : "none" }}
              >
                <Col xl={12} md={22} sm={20} xs={20}>
                  <Form.Item
                    name="model"
                    label="ระบุรุ่น"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="เลือกรุ่นของสินค้า"
                      onChange={onModelChange}
                    >
                      {data.map((model) => (
                        <Option key={model.id} value={model.id}>
                          {model.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="atom_modify" label="ระบุรุ่น">
                    <Checkbox
                      style={{ marginTop: "1rem" }}
                      onChange={onCheckBox}
                      checked={productSet.atom_modify}
                    >
                      <Text mark>พร้อมโม</Text>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xl={12} md={22} sm={20} xs={20}>
                  <Form.Item
                    name="color"
                    label="ระบุสี (color)"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="เลือกสีของสินค้า"
                      onChange={onIdChange}
                    >
                      {colors.map((color) => (
                        <Option key={color.id} value={color.id}>
                          {color.color}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                justify="center"
                style={{
                  display:
                    type === "mod" || type === "pod" || type === "box"
                      ? ""
                      : "none",
                }}
              >
                <Col xl={24} md={22} sm={20} xs={20}>
                  <Form.Item
                    name="color"
                    label="ระบุสี (color)"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="เลือกสีของสินค้า"
                      onChange={onIdChange}
                    >
                      {colors.map((color) => (
                        <Option key={color.id} value={color.id}>
                          {color.color}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Badge.Ribbon>
    </DivRoot>
  );
}

export default BoxesItem;
const DivRoot = styled.div`
  padding: 10px;
  border: 1px dashed #e5e5e5;
  height: 100%;
  border-radius: 5px;
`;
const TextPrice = styled.span`
  color: #4a67fa;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`;
const TextQty = styled.p`
  margin-bottom: 0.5rem;
  color: #000000;
  font-size: 14px;
`;
const DivPrice = styled.div`
  align-items: center;
`;
const Image = styled.img`
  height: auto;
  width: 100%;
  border-radius: 5px;
`;
