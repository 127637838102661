import React from "react";
import { Row, Col } from "antd";
import MenuLeftSide from "components/control-pages/navbar/SideMenu";
import ComponentProduct from "components/products/showProductByCatagory";
import PageHeader from "components/control-pages/banners/PageHeader";

function AllProduct() {
  return (
    <div>
      <Row gutter={[16, 8]} style={{ marginTop: "1rem" }}>
        <Col span={24}>
          <PageHeader />
        </Col>
        <Col xl={5} sm={7} xs={24}>
          <MenuLeftSide />
        </Col>
        <Col xl={19} sm={17} xs={24}>
          <ComponentProduct />
        </Col>
      </Row>
    </div>
  );
}

export default AllProduct;
