import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Divider } from "antd";
import { TitleDarkPurple } from "components/styled-component/TextStyle";
function LabelHeader(props) {
  const { text, icon } = props;
  return (
    <Row>
      <Col xl={1} lg={2} sm={1} xs={2}>
        <FontAwesomeIcon
          style={{ color: "#12143e", fontSize: "16px" }}
          icon={icon}
        />
      </Col>
      <Col xl={20} lg={22} sm={23} xs={22}>
        <TitleDarkPurple level={5}>{text}</TitleDarkPurple>
      </Col>
      <Divider style={{ display: props.divider === false ? "none" : "" }} />
    </Row>
  );
}

export default LabelHeader;
