import React, { useState } from "react";
import { Row, Col, message } from "antd";
import { faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";
import { PriceNumber } from "functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabelPurple } from "components/Labels";
import { ButtonOrange } from "components/styled-component/ButtonStyled";
import { useNavigate } from "react-router-dom";
import { AXIOS } from "services/host";
import { API_CART } from "services/apis";
function FooterCart(props) {
  const { disabled, onClose, currentData } = props;
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);

  const onProcessPayment = () => {
    setLoading(true);
    const formData = {
      products: currentData.filter((item) => item.product_type === "products"),
      product_set: currentData.filter(
        (item) => item.product_type === "product_set"
      ),
    };
    AXIOS({
      method: "post",
      url: API_CART,
      data: formData,
    })
      .then((res) => {
        const respondData = res.data.data;
        if (respondData) {
          localStorage.setItem(`resultGetCart`, JSON.stringify(respondData));
          localStorage.setItem(`localProduct`, JSON.stringify(formData));
          localStorage.setItem(`mergeProduct`, JSON.stringify(currentData));
          onClose();
          navigator("/confirm-order");

          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("เกิดข้อผิดพลาดไม่สามารถอัพเดตรายการสินค้าได้");
      });
  };

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col xl={7} xs={24}>
        <LabelPurple
          style={{ padding: "7px", textAlign: "center", height: "2.5rem" }}
        >
          <span>รวมยอดชำระ</span>
          <span
            style={{
              fontWeight: 600,
              fontSize: "16px",
              marginLeft: "10px",
            }}
          >
            {PriceNumber(props.totalCart)}
          </span>
        </LabelPurple>
      </Col>
      <Col xl={6} xs={24}>
        <ButtonOrange
          style={{ height: "2.5rem" }}
          loading={loading}
          disabled={disabled}
          onClick={onProcessPayment}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: "16px",
              marginLeft: "10px",
            }}
          >
            ชำระเงิน
          </span>
          <FontAwesomeIcon
            style={{
              fontWeight: 600,
              fontSize: "20px",
              marginLeft: "10px",
            }}
            icon={faMoneyCheckAlt}
          />
        </ButtonOrange>
      </Col>
    </Row>
  );
}

export default FooterCart;
