import React from "react";
import styled from "styled-components";
import { Row, Col, Divider, Typography } from "antd";
import {
  TextLightGrey,
  TextDarkGrey,
  TitleLightPurple,
  TitleDarkGrey,
  TitleDarkPurple,
  TextDarkPurple,
} from "components/styled-component/TextStyle";
import { URL_PATH } from "services/host";
import { PriceNumber } from "functions/index";
import ImageDefault from "images/no-image.png";
const { Text } = Typography;
function RecieptDetail(props) {
  const { data } = props;
  const ad = data.addresses;
  const sh = data.shipments;
  const arrBox = (item) => {
    if (item.product_type !== "normal") {
      const arr = [];
      const objArray = Object.entries(item.product_set);
      objArray.forEach(([key, value]) => {
        if (key === item.product_type || key === "atom" || key === "cotton") {
          if (value?.product_list?.id !== undefined) {
            arr.push({
              type: key,
              value: value,
              isFree: key === "cotton" && item?.free_cotton,
            });
          }
        } else if (key === "charger") {
          const tmp = value ?? [];
          tmp.forEach((itemCharge) => {
            arr.push({
              type: key,
              value: itemCharge,
            });
          });
        }
      });
      return arr;
    }
  };
  return (
    <DivRoot>
      <TextLightGrey style={{ fontSize: "14px" }}>
        วันที่สั่งซื้อสินค้า
      </TextLightGrey>
      <br />
      <TextDarkGrey style={{ fontSize: "14px" }}>
        {data?.order_date !== undefined
          ? new Date(data?.order_date)?.toLocaleDateString()
          : null}
      </TextDarkGrey>
      <Row gutter={[8, 8]}>
        <Col xl={18} sm={18} xs={24}>
          <TextLightGrey style={{ fontSize: "14px" }}>
            รายละเอียดการจัดส่ง
          </TextLightGrey>
          <br />
          <TextDarkGrey style={{ fontSize: "14px" }}>
            {`${ad?.first_name} ${ad?.last_name} เบอร์โทร ${ad?.phone} `}
          </TextDarkGrey>
          <TextDarkGrey style={{ fontSize: "14px" }}>
            {`ที่อยู่ : ${ad?.address} ตำบล ${ad?.sub_district} อำเภอ ${ad?.district} จังหวัด ${ad?.province} รหัสไปรษณีย์ ${ad?.postcode}  `}
          </TextDarkGrey>
        </Col>
        <Col xl={24} sm={24} xs={24}>
          <TextLightGrey style={{ fontSize: "14px" }}>
            {`(จัดส่งโดย ${sh?.name} )`}
          </TextLightGrey>
          <br />
          <ImageStyle src={`${URL_PATH}${sh?.image_file}`} />
        </Col>
      </Row>
      <div id="list-items" style={{ marginTop: "1rem" }}>
        <Row gutter={[8, 8]}>
          {data.res_opl?.map((item, index) => (
            <ColItem key={index} span={24}>
              <Row style={{ padding: "0px 1rem 5px 0px" }}>
                <Col span={4}>
                  <img
                    style={{ padding: "10px", borderRadius: "10px" }}
                    width="100%"
                    height="auto"
                    alt=""
                    src={
                      item.product_lists[0]?.product_list_images?.path
                        ? `${URL_PATH}${item.product_lists[0]?.product_list_images.path}`
                        : ImageDefault
                    }
                  ></img>
                </Col>
                <Col span={20} style={{ marginTop: "1rem" }}>
                  <TitleDarkPurple style={{ fontSize: "14px" }}>
                    {item.name}
                  </TitleDarkPurple>
                  {item.color ? (
                    <DivOption>
                      ตัวเลือกสินค้า
                      {item.color ? `สี ${item.color}` : null}
                    </DivOption>
                  ) : null}

                  <div style={{ marginTop: "10px" }}>
                    <TextDarkGrey>
                      {PriceNumber(item.price_per_unit)}
                    </TextDarkGrey>
                    <TextLightGrey type="12px" style={{ marginLeft: "10px" }}>
                      {`ราคาสินค้า (บาท)/ ${item.qty} ชิ้น`}
                    </TextLightGrey>
                    <div>
                      <Text
                        style={{ fontSize: "12px" }}
                        type="danger"
                      >{`ส่วนลดสินค้า ${item.discount} บาท`}</Text>
                    </div>
                  </div>
                  <DivQty>
                    <Row gutter={8}>
                      <Col xl={5} lg={7} sm={5} xs={24}>
                        <TitleDarkGrey style={{ fontSize: "14px" }}>
                          {`จำนวน ${item.amount} ชิ้น`}
                        </TitleDarkGrey>
                      </Col>
                      <Col xl={9} lg={4} sm={10} xs={24}>
                        <DividerStyle dashed />
                      </Col>
                      <Col xl={5} lg={6} sm={5} xs={12}>
                        <TextLightGrey type="12px">
                          {`รวมค่าสินค้า`}
                        </TextLightGrey>
                      </Col>
                      <Col xl={5} lg={7} sm={4} xs={12}>
                        <TitleLightPurple
                          style={{ fontSize: "14px", textAlign: "end" }}
                        >
                          {PriceNumber(item.net_price)}
                        </TitleLightPurple>
                      </Col>
                    </Row>
                  </DivQty>
                  <div
                    style={{
                      display: item.product_type !== "normal" ? "" : "none",
                    }}
                  >
                    <Row gutter={[8, 8]}>
                      {arrBox(item)?.map((product, indexBox) => {
                        const detailItem = product.value.product_list;
                        return (
                          <ColItems key={indexBox} span={24}>
                            <Row gutter={8}>
                              <Col span={4}>
                                <img
                                  height="auto"
                                  width="100%"
                                  style={{
                                    padding: "5px",
                                    borderRadius: "10px",
                                  }}
                                  alt=""
                                  src={
                                    detailItem?.product_list_images?.path !==
                                    undefined
                                      ? `${URL_PATH}${detailItem?.product_list_images?.path}`
                                      : ImageDefault
                                  }
                                />
                              </Col>
                              <Col span={14} xs={12}>
                                <Row>
                                  <Col span={24}>
                                    <TextDarkGrey
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {product.value.name}
                                    </TextDarkGrey>
                                  </Col>
                                  <Col span={24}>
                                    <TextDarkPurple
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >{`จำนวน : ${detailItem.qty}`}</TextDarkPurple>
                                  </Col>
                                  {detailItem.color ? (
                                    <Col span={24}>
                                      <TextLightGrey type="12px">
                                        {`ตัวเลือก สี ${detailItem.color}`}
                                      </TextLightGrey>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                              <Col
                                xl={6}
                                xs={8}
                                style={{
                                  paddingRight: "10px",
                                }}
                              >
                                <TitleDarkGrey
                                  level={5}
                                  style={{ textAlign: "end", fontSize: "12px" }}
                                >
                                  {PriceNumber(detailItem.price_show)}
                                </TitleDarkGrey>
                                <DivFree
                                  style={{
                                    display:
                                      product.isFree === true ? "" : "none",
                                  }}
                                >
                                  สินค้าแถมฟรี
                                </DivFree>
                              </Col>
                            </Row>
                          </ColItems>
                        );
                      })}
                    </Row>
                  </div>
                </Col>
              </Row>
            </ColItem>
          ))}
        </Row>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <Row>
          <Col span={24} style={{ marginTop: "2rem" }}>
            <Row gutter={8} justify="space-between" align="middle">
              <Col xl={18} lg={15}>
                <TitleDarkGrey level={5}>ราคาสินค้า</TitleDarkGrey>
              </Col>
              <Col xl={6} lg={9} style={{ textAlign: "end" }}>
                <TitleDarkGrey level={5}>
                  {PriceNumber(data?.total_price)}
                </TitleDarkGrey>
              </Col>
            </Row>
          </Col>
        </Row>
        <DividerStyle dashed />
        <Row>
          <Col span={24} style={{ marginTop: "2rem" }}>
            <Row gutter={8} justify="space-between" align="middle">
              <Col xl={18} lg={15}>
                <TitleDarkGrey level={5}>ราคาค่าจัดส่ง</TitleDarkGrey>
              </Col>
              <Col xl={6} lg={9} style={{ textAlign: "end" }}>
                <TitleDarkGrey level={5}>
                  {PriceNumber(sh?.price)}
                </TitleDarkGrey>
              </Col>
            </Row>
          </Col>
        </Row>
        <DividerStyle dashed />
      </div>

      <div style={{ marginTop: "1.5rem" }}>
        <Row>
          <Col span={24} style={{ marginTop: "1rem" }}>
            <Row gutter={8} justify="space-between" align="middle">
              <Col xl={18} lg={15}>
                <Text>ส่วนลดทั้งหมด</Text>
              </Col>
              <Col xl={6} lg={9} style={{ fontSize: "14px", textAlign: "end" }}>
                <Text type="danger">{PriceNumber(data?.total_discount)}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={8} justify="space-between" align="middle">
              <Col xl={18} lg={15}>
                <TitleDarkGrey level={5}>รวมยอดชำระทั้งหมด</TitleDarkGrey>
              </Col>
              <Col xl={6} lg={9} style={{ textAlign: "end" }}>
                <TitleLightPurple level={5}>
                  {PriceNumber(data?.total_net_price)}
                </TitleLightPurple>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </DivRoot>
  );
}

export default RecieptDetail;
const DivRoot = styled.div`
  height: 100%;
  border-radius: 10px;
  background: #fafafa;
  padding: 1rem 2rem 1rem 2rem;
`;
const ImageStyle = styled.img`
  width: 100px;
  height: 50px;
`;
const DivFree = styled.div`
  margin-left: 5px;
  border-radius: 5px;
  background: #52c419;
  color: white;
  width: fit-content;
  padding: 1px 1px 1px 3px;
  font-size: 12px;
`;
const DividerStyle = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 4px 0px;
  }
`;
const ColItems = styled(Col)`
  background: #fafafa;
  border-radius: 5px;
  padding: 3px 10px 2px 3px;
`;

const ColItem = styled(Col)`
  border-radius: 10px;
  padding: 0px;
  border: 1px solid #e5e5e5;
`;
const DivQty = styled.div`
  margin-top: 0.5rem;
`;
const DivOption = styled.div`
  font-size: 12px;
  width: fit-content;
  color: #858383;
  background-color: #e5e5e5;
  border-radius: 5px;
  padding: 2px 5px 0px 5px;
`;
