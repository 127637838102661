import React, { useEffect, useContext } from "react";
import { Modal, Row, Col, Space } from "antd";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { TitleDarkPurple } from "components/styled-component/TextStyle";
import { InputAmount } from "components/Inputs";
import ButtonAddToCart from "components/buttons/ButtonAddToCart";
import { PriceNumber, UpdateCart } from "functions/index";
import { CouterCartContext } from "store/CouterCartProvider";
import { ButtonOptions } from "components/buttons";
import { RenderImage } from "functions";

function ModalProduct(props) {
  const { visible, onHiding, item } = props;
  const { addProduct, setProduct, product } = useContext(CouterCartContext);
  const productData = item?.product_lists[0] ?? {};
  const productListTmp = item?.product_lists ?? [];
  const onValueUpdate = (value) => {
    const result = {
      ...product,
      amount: value,
    };
    setProduct(result);
  };
  const callBackSuccess = (amount, product) => {
    addProduct({ amount, product });
    onCancle();
  };
  useEffect(() => {
    if (productListTmp.length > 1) {
      setProduct({ ...product, product_list_id: null });
    } else if (productData.id !== undefined) {
      setProduct({ ...product, product_list_id: productData.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData.id]);
  const onCancle = () => {
    setProduct({
      amount: 1,
      product_list_id: "",
    });
    onHiding();
  };
  const onClickColor = (event) => {
    const result = {
      ...product,
      product_list_id: event.id,
    };
    setProduct(result);
  };
  const onClickAddToCart = () => {
    if (!product.product_list_id) {
      Modal.warning({
        title: "แจ้งเตือน!",
        content: "กรุณาระบุตัวเลือกของสินค้า",
        okText: "ตกลง",
      });
    } else {
      UpdateCart(product, "products", callBackSuccess);
    }
  };
  return (
    <ModalStyled
      width={600}
      footer={null}
      visible={visible}
      onCancel={onCancle}
      closeIcon={<FontAwesomeIcon icon={faTimesCircle} />}
      title="รายละเอียดสินค้า"
    >
      {item ? (
        <div>
          <Row justify="center" gutter={[16, 16]}>
            <Col xl={12} sm={12} xs={24}>
              <ImageProduct
                src={RenderImage(item?.product_cover_images?.path)}
              />
            </Col>
            <Col xl={12} sm={12} xs={24}>
              <TitleDarkPurple level={4}>{productData.name}</TitleDarkPurple>
              <SpanPrice>{PriceNumber(item.price_show)}</SpanPrice>
              <SpanText>{`ราคาสินค้า (บาท)`}</SpanText>
              <div>
                {productData.coil_size ? (
                  <SpanText>{`ขนาด coil : ${productData.coil_size}`}</SpanText>
                ) : null}
              </div>
              {item.detail ? (
                <SpanText>{`รายละเอียด : ${item.detail}`}</SpanText>
              ) : null}
              {productListTmp.length > 1 ? (
                <Space>
                  {item?.product_lists?.map((item, index) => {
                    return (
                      <ButtonOptions
                        key={index}
                        onClick={() => onClickColor(item)}
                      >
                        {item.color}
                      </ButtonOptions>
                    );
                  })}
                </Space>
              ) : null}
              <div style={{ marginTop: "4vw" }}>
                <SpanText>{`ระบุจำนวนสินค้า`}</SpanText>
                <Row gutter={[8, 8]}>
                  <Col md={12} xs={12}>
                    <InputAmount onValueUpdate={onValueUpdate} />
                  </Col>
                  <Col md={12} xs={12}>
                    <ButtonAddToCart size="14px" onClick={onClickAddToCart} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </ModalStyled>
  );
}

export default ModalProduct;

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
  }
  .ant-modal-body {
    padding: 2rem;
    background-color: #fafafa;
    border-radius: 20px !important;
  }
`;

const SpanText = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
`;
const SpanPrice = styled.strong`
  font-size: 16px;
  color: #ff0018;
  border-radius: 10px;
`;
const ImageProduct = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 5px;
  @media (min-width: 768px) {
    height: 200px;
  }
  @media (max-width: 768px) {
    height: 230px;
  }
`;
