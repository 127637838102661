import React, { useEffect, useState } from "react";
import { Row, Col, Grid, Tag, Avatar, Input } from "antd";
import MenuLeftSide from "components/control-pages/navbar/SideMenu";
import ComponentProduct from "components/products/showProductByCatagory";
import Banners from "components/control-pages/banners/Banners";
import ImageShop from "images/shop.png";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const { Search } = Input;
const { useBreakpoint } = Grid;
function HomeProducts() {
  const screens = useBreakpoint();
  const { header,catId } = useParams();
  const [searchKeyword, setSearchKeyword] = useState('');
const [value,setValue] = useState('')

  const onSearch = (value) => {
    setSearchKeyword(value)
  }
  const onChange = (e) => {
    setValue(e.target.value)
  }
  useEffect(() => {
    setSearchKeyword('')
    setValue('')
  },[catId])
  return (
    <div>
      <Banners />
      <Row gutter={[16, 8]} style={{ marginTop: "1rem" }}>
        <Col xl={5} sm={7} xs={24}>
          <MenuLeftSide />
        </Col>
        <Col xl={19} sm={17} xs={24}>
          {screens.xs === true ? null : (
            <Col span={24}>
              <div
                style={{
                  margin: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TagCat
                  icon={
                    <Avatar
                      style={{ marginRight: "10px" }}
                      shape="square"
                      size={28}
                      src={ImageShop}
                    />
                  }
                >
                  {`${header}`}
                </TagCat>
                <Search
                  style={{ width: "300px" }}
                  placeholder="ค้นหาสินค้า"
                  onSearch={onSearch}
                  value={value}
                  onChange={onChange}
                  allowClear
                />
              </div>
            </Col>
          )}
          <ComponentProduct keyword={searchKeyword} />
        </Col>
      </Row>
    </div>
  );
}

export default HomeProducts;
const TagCat = styled(Tag)`
  color: #3b5999;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  border: unset;
`;
