import React, { useEffect, useState } from "react";
import { Row, Col, Select, Button, Popconfirm, Tag, Typography } from "antd";
import styled from "styled-components";
import ImageDefault from "images/no-image.png";
import { InputAmount } from "components/Inputs";
import { URL_PATH } from "services/host";
import {
  TextLightGrey,
  TextDarkGrey,
  TitleDarkPurple,
} from "components/styled-component/TextStyle";
import { PriceNumber } from "functions";

const { Option } = Select;
const { Text } = Typography;
export default function ItemProduct(props) {
  const {
    index,
    item,
    ondDeleteProduct,
    onValueUpdate,
    onIdChange,
    dataModalState,
  } = props;
  const [currentImage, setCurrentImage] = useState("");
  const onColorChange = (e, index) => {
    const productList = item.product_lists;
    const found = productList.find((x) => x.id === e);
    const newImage = found?.product_list_images?.path;
    setCurrentImage(newImage);
    onIdChange(e, index);
  };
  useEffect(() => {
    const img = item.product_lists
      ? `${item.product_lists[0]?.product_list_images.path}`
      : ImageDefault;
    setCurrentImage(img);
  }, [item]);
  return (
    <div>
      <Row
        gutter={8}
        style={{
          background: "#fafafa",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Col xl={3} sm={3} xs={4}>
          <ImagesProduct src={`${URL_PATH}${currentImage}`} />
        </Col>
        <Col xl={21} sm={21} xs={20}>
          <Row gutter={4}>
            <Col xl={18} sm={18} xs={16}>
              <TitleDarkPurple style={{ fontSize: "14px" }}>
                {item.name}
              </TitleDarkPurple>
              <TextDarkGrey>{`฿${PriceNumber(
                item.price_per_unit
              )}`}</TextDarkGrey>
              <TextLightGrey type="12px">{` ราคา/ ${item.qty} ชิ้น`}</TextLightGrey>
              {item.discount && (
              <div>
                <Text style={{fontSize:'12px'}} type="danger">{`ส่วนลดสินค้า ${item.discount} บาท`}</Text>
              </div>
            )}
            </Col>
            <Col xl={6} sm={6} xs={8} style={{ textAlign: "end" }}>
              <Popconfirm
                placement="top"
                title={"ต้องการลบสินค้าชิ้นนี้"}
                onConfirm={() => ondDeleteProduct(index)}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <DeleteProduct size="small">ลบสินค้า</DeleteProduct>
              </Popconfirm>
            </Col>
          </Row>
          <Row gutter={4} style={{ marginTop: "5px" }} align="middle">
            <Col xl={6} sm={6} xs={12}>
              <InputAmount
                onValueUpdate={(value) => onValueUpdate(value, index)}
                defaultValue={item.amount}
              />
              {item?.color ? (
                <Tag
                  style={{
                    marginTop: "5px",
                    color: "white",
                    background: "#000000",
                    borderRadius: "5px",
                    fontWeight: 600,
                  }}
                >{`สี${item.color}`}</Tag>
              ) : null}
            </Col>
            <Col xl={18} sm={18} xs={12}>
              <TitleDarkPurple style={{ fontSize: "14px", textAlign: "end" }}>
                {`฿${PriceNumber(item.amount * item.price_per_unit)}`}
              </TitleDarkPurple>
            </Col>
          </Row>
          <Row gutter={4} align="middle" style={{ marginTop: "10px" }}>
           
            <Col xl={6} sm={6} xs={12}>
              {item.product_type === "normal" &&
              item?.product_lists.length > 1 ? (
                <Select
                  defaultValue={item.product_list_id}
                  value={dataModalState[index]?.product_list_id}
                  style={{ width: "100%" }}
                  onChange={(e) => onColorChange(e, index)}
                >
                  {item?.product_lists?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.color}
                    </Option>
                  ))}
                </Select>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
const DeleteProduct = styled(Button)`
  border-radius: 10px;
  border: unset;
  width: 5rem;
  padding: 2px 5px 2px 5px;
  background: #fff2e8;
  color: #d4380e;
  fontsize: 12px;
`;
const ImagesProduct = styled.img`
  border-radius: 5px;
  width: 100%;
  height: auto;
  padding: 5px;
`;
