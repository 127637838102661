import styled from "styled-components";

const LabelCatStyled = styled.div`
  color: #5f2083;
  border-radius: 30px;
  padding: 5px;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
`;
export default LabelCatStyled;
