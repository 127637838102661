

const typeArr = [
    {type: 'atom',name: 'อะตอม'},
    {type: 'battery',name: 'แบตเตอรี่'},
    {type: 'box',name: 'กล่อง'},
    {type: 'coil',name: 'คอยล์'},
    {type: 'charger',name: 'รางชาร์จ'},
    {type: 'cotton',name: 'สำลี'},
    {type: 'freebase',name: 'น้ำยาฟรีเบส'},
    {type: 'mod',name: 'มอท'},
    {type: 'pod',name: 'พอท'},
    {type: 'salt_nic',name: 'น้ำยาซอลท์นิค'},
    {type: 'wire',name: 'ลวด'},
    {type: 'accessory', name: 'อุปกรณ์ต่างๆ'}
]

export const renderNameType = (type) => {
    const reuslt = typeArr.find((item) => item.type === type)?.name ?? ''
    return reuslt
}