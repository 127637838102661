import React, { useState, useCallback, useEffect } from "react";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { TextDarkGrey } from "components/styled-component/TextStyle";

function InputAmount(props) {
  const [value, setValue] = useState(props.defaultValue ?? 1);
  const onMinusValue = useCallback(() => {
    if (value !== 0) {
      setValue(value - 1);
    }
  }, [value]);
  // const onPlusValue = useCallback(() => {
  //   setValue(value + 1);
  // }, [value]);
  useEffect(() => {
    props.onValueUpdate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // useEffect(() => {

  // }, [props.onValueUpdate]);

  return (
    <RowStyled justify="center">
      <ColMinus span={5}>
        <FontAwesomeIcon
          style={{
            cursor: value === 1 ? "not-allowed" : "pointer",
            fontSize: "12px",
          }}
          color="#2F49C9"
          onClick={value === 1 ? null : onMinusValue}
          icon={faMinus}
        />
      </ColMinus>
      <ColValue span={14}>
        <TextDarkGrey type="14px">{props.defaultValue ?? value}</TextDarkGrey>
      </ColValue>
      <ColPlus span={5}>
        <FontAwesomeIcon
          style={{ cursor: "pointer", fontSize: "12px" }}
          color="#2F49C9"
          onClick={() => setValue((prev) => ++prev)}
          icon={faPlus}
        />
      </ColPlus>
    </RowStyled>
  );
}

export default InputAmount;

const RowStyled = styled(Row)`
  /* padding: ${(props) => props.pd ?? "0.5rem 5px 0.5rem 5px"}; */
  height: 32px;
  border-radius: 5px !important;
  border: 1px solid #f0f5ff;
  text-align: center;
  background-color: #ffffff;
`;
const ColMinus = styled(Col)`
  background: #f0f5ff;
  border-radius: 3px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ColPlus = styled(Col)`
  background: #f0f5ff;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ColValue = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
