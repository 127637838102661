import styled from "styled-components";
import { Button } from "antd";
export const ButtonOrange = styled(Button)`
  font-size: ${(props) => props.type ?? "12px"};
  height: 2rem;
  border-radius: 5px;
  background-color: #f26440;
  color: #ffffff;
  border: unset;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: #ffe4dc;
    color: #f26440;
  }
  :focus {
    background-color: #f26440;
    color: #ffffff;
    border: unset;
  }
`;

export const ButtonDarkPurple = styled.button`
  font-size: ${(props) => props.type ?? "12px"};
  line-height: 15px;
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2a2e6e;
  color: #ffffff;
  border: unset;
  font-weight: 500;
`;
export const ButtonSet = styled(Button)`
  font-size: ${(props) => props.type ?? "12px"};
  line-height: 15px;
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  background-color: #2a2e6e;
  color: #ffffff;
  border: unset;
  font-weight: 500;
  cursor: pointer;
  :focus {
    background-color: #2a2e6e;
    color: #ffffff;
    border: unset;
  }
  :hover {
    background-color: #a3a7d3;
    color: #ffffff;
  }
`;
