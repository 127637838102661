import React, { useEffect, useState, useContext } from "react";
import {
  ComponentDetailBox,
  ComponentCustomBox,
} from "components/productBoxes";
import styled from "styled-components";
import { Row, Col } from "antd";
import { AXIOS } from "services/host";
import { API_PRODUCT } from "services/apis";
import { useLocation } from "react-router-dom";
import { ResultError } from "components/control-pages/resultPage";
import { CouterCartContext } from "store/CouterCartProvider";
import MenuLeftSide from "components/control-pages/navbar/SideMenu";
import LoadingPage from "components/control-pages/resultPage/Loading";
import BreadCrumbs from "components/control-pages/banners/BreadcrumsProductBox";

function ProductBoxesDetail() {
  let location = useLocation();
  const productId = new URLSearchParams(location.search).get("v");
  const { readProductDetail, productDetail, resetCustomsetBox } =
    useContext(CouterCartContext);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);

  const fectData = () => {
    window.scrollTo(0, 0);
    resetCustomsetBox();
    AXIOS({
      url: API_PRODUCT,
      method: "get",
      params: { product_id: productId },
    })
      .then((res) => {
        const result = res.data.data;
        setIsError(false);
        readProductDetail(result);
        setLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fectData, [productId]);
  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <BreadCrumbs />
        </Col>
      </Row>
      {loading === true ? (
        <LoadingPage />
      ) : isError === true ? (
        <ResultError />
      ) : (
        <Row style={{ marginTop: "1rem" }} justify="center" gutter={[16, 16]}>
          <Col xl={5} sm={7} xs={24}>
            <MenuLeftSide />
          </Col>
          <Col xl={19} sm={22} xs={23}>
            <DivDetail>
              <ComponentDetailBox />
            </DivDetail>
            <DividerDash />
            <DivCustom>
              {productDetail?.product_set_id !== 0? (
                <ComponentCustomBox />
              ) : null}
            </DivCustom>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ProductBoxesDetail;
const DividerDash = styled.hr`
  border-top: 2px dashed #d6d6d6;
  border-bottom: unset;
  letter-spacing: 5px;
`;
const DivDetail = styled.div`
  background-color: #fafafa;
  padding-top: 5vh;
  margin-bottom: 2rem;
  border-radius: 10px;
`;
const DivCustom = styled.div`
  margin-top: 1rem;
  margin-bottom: 5rem;
`;
