import React, { useContext } from "react";
import styled from "styled-components";
import { Row, Col, Divider, Tag, Typography } from "antd";
import {
  TitleDarkGrey,
  TitleDarkPurple,
  TextLightGrey,
  TextDarkGrey,
  TitleLightPurple,
  TextDarkPurple,
  TitleRed,
} from "components/styled-component/TextStyle";
import { PriceNumber } from "functions";
import { URL_PATH } from "services/host";
import ImageDefault from "images/no-image.png";
import { CouterCartContext } from "store/CouterCartProvider";
const { Text } = Typography;
function OrderLists(props) {
  const { data } = props;
  const { shipment_price } = useContext(CouterCartContext);
  const productList = data.order_list;

  const arrBox = (item) => {
    if (item.product_type !== "normal") {
      const arr = [];
      const objArray = Object.entries(item.product_set);
      objArray.forEach(([key, value]) => {
        if (
          key === item.product_type ||
          key === "atom" ||
          key === "cotton"
        ) {
          if (value?.product_list?.id !== undefined) {
            arr.push({
              type: key,
              value: value,
              isFree: key === "cotton" && item?.free_cotton,
            });
          }
        } else if (key === "charger") {
          const tmp = value ?? [];
          tmp.forEach((itemCharge) => {
            arr.push({
              type: key,
              value: itemCharge,
            });
          });
        }
      });
      return arr;
    }
  };
  return (
    <div>
      <DivRoot>
        <div id="title">
          <TitleDarkGrey level={5}>รายการสินค้า</TitleDarkGrey>
          <Divider />
        </div>
        <div id="list-items">
          <Row gutter={[8, 16]}>
            {productList?.map((item, index) => (
              <ColItem key={index} span={24}>
                <Row style={{ padding: "0px 1rem 5px 0px" }}>
                  <Col span={4}>
                    <img
                      style={{ padding: "10px", borderRadius: "10px" }}
                      width="100%"
                      height="auto"
                      alt=""
                      src={
                        item.product_lists[0]?.product_list_images?.path
                          ? `${URL_PATH}${item.product_lists[0]?.product_list_images.path}`
                          : ImageDefault
                      }
                    ></img>
                  </Col>
                  <Col span={20} style={{ marginTop: "1rem" }}>
                    <TitleDarkPurple style={{ fontSize: "14px" }}>
                      {item.name}
                    </TitleDarkPurple>
                    {item.color ? (
                      <DivOption>
                        ตัวเลือกสินค้า
                        {item.color ? `สี ${item.color}` : null}
                      </DivOption>
                    ) : null}

                    <div style={{ marginTop: "10px" }}>
                      <TextDarkGrey>
                        {PriceNumber(item.price_per_unit)}
                      </TextDarkGrey>
                      <TextLightGrey type="12px" style={{ marginLeft: "10px" }}>
                        {`ราคาสินค้า (บาท)/ ${item.qty} ชิ้น`}
                      </TextLightGrey>
                      <div>
                        <Text
                          style={{ fontSize: "12px" }}
                          type="danger"
                        >{`ส่วนลดสินค้า ${item.discount} บาท`}</Text>
                      </div>
                    </div>
                    <DivQty>
                      <Row gutter={8}>
                        <Col xl={5} lg={7} sm={5} xs={24}>
                          <TitleDarkGrey style={{ fontSize: "14px" }}>
                            {`จำนวน ${item.amount} ชิ้น`}
                          </TitleDarkGrey>
                        </Col>
                        <Col xl={9} lg={4} sm={10} xs={24}>
                          <Divider dashed />
                        </Col>
                        <Col xl={5} lg={6} sm={5} xs={12}>
                          <TextLightGrey type="12px">
                            {`รวมค่าสินค้า`}
                          </TextLightGrey>
                        </Col>
                        <Col xl={5} lg={7} sm={4} xs={12}>
                          <TitleLightPurple
                            style={{ fontSize: "16px", textAlign: "end" }}
                          >
                            {PriceNumber(item.net_price)}
                          </TitleLightPurple>
                        </Col>
                      </Row>
                    </DivQty>
                    <div
                      style={{
                        display: item.product_type !== "normal" ? "" : "none",
                      }}
                    >
                      <Row gutter={[8, 8]}>
                        {arrBox(item)?.map((product, indexBox) =>  {
                          const detailItem = product.value.product_list
                          return (
                            <ColItems key={indexBox} span={24}>
                            <Row gutter={8}>
                              <Col span={4}>
                                <img
                                  height="auto"
                                  width="100%"
                                  style={{
                                    padding: "5px",
                                    borderRadius: "10px",
                                  }}
                                  alt=""
                                  src={
                                    detailItem?.product_list_images?.path !==
                                    undefined
                                      ? `${URL_PATH}${detailItem?.product_list_images?.path}`
                                      : ImageDefault
                                  }
                                />
                              </Col>
                              <Col span={14} xs={12}>
                                <Row>
                                  <Col span={24}>
                                    <TextDarkGrey
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {product.value.name}
                                    </TextDarkGrey>
                                  </Col>
                                  <Col span={24}>
                                    <TextDarkPurple
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >{`จำนวน : ${detailItem.qty}`}</TextDarkPurple>
                                  </Col>
                                  {detailItem.color ? (
                                    <Col span={24}>
                                      <TextLightGrey type="12px">
                                        {`ตัวเลือก สี ${detailItem.color}`}
                                      </TextLightGrey>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                              <Col
                                xl={6}
                                xs={8}
                                style={{
                                  paddingRight: "10px",
                                }}
                              >
                                <TitleDarkGrey
                                  level={5}
                                  style={{ textAlign: "end", fontSize: "12px" }}
                                >
                                  {PriceNumber(detailItem.price_show)}
                                </TitleDarkGrey>
                                <DivFree
                                  style={{
                                    display:
                                      product.isFree === true ? "" : "none",
                                  }}
                                >
                                  สินค้าแถมฟรี
                                </DivFree>
                              </Col>
                            </Row>
                          </ColItems>
                          )
                        }
                        
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </ColItem>
            ))}
          </Row>
        </div>
      </DivRoot>
      <DivToTal>
        <Row>
          <Col span={24} style={{ marginTop: "1rem" }}>
            <Row justify="space-between" align="middle">
              <Col span={18} xs={16}>
                <Tag color="magenta">ค่าส่ง</Tag>
              </Col>
              <Col span={6} xs={8} style={{ textAlign: "end" }}>
                <Text strong type="danger">
                  {PriceNumber(shipment_price)}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginTop: "1rem" }}>
            <Row justify="space-between" align="middle">
              <Col span={18} xs={16}>
                <TitleDarkGrey level={5}>รวมค่าสินค้า</TitleDarkGrey>
              </Col>
              <Col span={6} xs={8} style={{ textAlign: "end" }}>
                <TitleDarkGrey level={5}>
                  {PriceNumber(data.total_price)}
                </TitleDarkGrey>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={8} justify="space-between" align="middle">
              <Col xl={18} lg={15}>
                <TitleDarkGrey level={5}>สรุปยอดชำระ</TitleDarkGrey>
              </Col>
              <Col xl={6} lg={9} style={{ textAlign: "end" }}>
                <TitleRed level={4}>{`${PriceNumber(
                  data?.total_price + shipment_price
                )} บาท`}</TitleRed>
              </Col>
            </Row>
          </Col>
        </Row>
      </DivToTal>
    </div>
  );
}

export default OrderLists;
const DivFree = styled.div`
  margin-left: 5px;
  border-radius: 5px;
  background: #52c419;
  color: white;
  width: fit-content;
  padding: 1px 1px 1px 3px;
  font-size: 12px;
`;
const DivToTal = styled.div`
  background: #f6f6f6;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 10px;
`;
const ColItems = styled(Col)`
  background: #fafafa;
  border-radius: 5px;
  padding: 3px 10px 2px 3px;
`;
const DivRoot = styled.div`
  border-radius: 10px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border: 1px solid #e5e5e5;
`;
const ColItem = styled(Col)`
  border-radius: 10px;
  padding: 0px;
  border: 1px solid #e5e5e5;
`;
const DivQty = styled.div`
  margin-top: 0.5rem;
`;
const DivOption = styled.div`
  font-size: 12px;
  width: fit-content;
  color: #858383;
  background-color: #e5e5e5;
  border-radius: 5px;
  padding: 2px 5px 0px 5px;
`;
