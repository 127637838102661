import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Avatar } from "antd";
import ProductSlideAllCatHome from "components/SliderImage/allCatHome";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ImageShop from "images/shop.png";
import { API_CATEGORY } from "services/apis";
import { AXIOS } from "services/host";

export default function ShowAllProductAllCatagory() {
  const navigate = useNavigate();
  const [arrayCat, setArray] = useState([]);
  const onClickSeeAll = (item) => {
    navigate(`/all/${item.name}/categoryId/${item.id}`);
  };
  const fetchData = () => {
    AXIOS({
      url: API_CATEGORY,
      method: "get",
    })
      .then((res) => {
        //เอาไปใช้ในเมนู
        const result = res.data.data;
        setArray(result);
      })
      .catch((err) => {
      });
  };
  useEffect(fetchData ,[])
  return (
    <div>
      {arrayCat.map((item) => (
        <Row
          style={{ marginBottom: "2rem" }}
          key={item.id}
          justify="center"
          gutter={[4, 8]}
        >
          <Col span={22}>
            <Row justify="space-between">
              <Col xl={4} lg={4} sm={10} xs={18}>
                <TagCat
                  icon={
                    <Avatar
                      style={{ marginRight: "10px" }}
                      shape="square"
                      size={28}
                      src={ImageShop}
                    />
                  }
                >
                  {item.name}
                </TagCat>
              </Col>
              <Col style={{ textAlign: "end" }} xl={3} lg={5} sm={5} xs={6}>
                <ButtonSeeAll
                  onClick={() => onClickSeeAll(item)}
                  type="link"
                >{`ดูทั้งหมด >>`}</ButtonSeeAll>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <DivSlideProduct>
              <ProductSlideAllCatHome
                header={item.name}
                cateId={item.id}
              />
            </DivSlideProduct>
          </Col>
        </Row>
      ))}
    </div>
  );
}
const ButtonSeeAll = styled(Button)`
  color: #5f2083;
  font-size: 14px;
`;
const TagCat = styled(Tag)`
  color: #3b5999;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  border: unset;
`;
const DivSlideProduct = styled.div`
  border-radius: 10px;
`;
