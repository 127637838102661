import React, { useEffect, useState } from "react";
import { TitleDarkPurple } from "components/styled-component/TextStyle";
import { Row, Col, message, Result, Button } from "antd";
import { OrderDetail, OrderList } from "components/confirmOrder";
import { AXIOS } from "services/host";
import { API_PREPARE_ORDER } from "services/apis";
import LoadingPage from "components/control-pages/resultPage/Loading";
import { useNavigate } from "react-router-dom";
function ConfirmOrder() {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem(`localProduct`));
    AXIOS({
      method: "post",
      url: API_PREPARE_ORDER,
      data: obj,
    })
      .then((res) => {
        const respondData = res.data.data;
        setData(respondData);
        setLoading(false);
      })
      .catch((err) => {
        message.error("เกิดข้อผิดพลาด");
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingPage />
      ) : data.order_list ? (
        <Row gutter={32} justify="center" style={{ marginBottom: "2rem" }}>
          <Col span={24} style={{ margin: "1rem 0px 0.5rem 0px" }}>
            <TitleDarkPurple level={4}>ยืนยันคำสั่งซื้อ</TitleDarkPurple>
          </Col>
          <Col xl={10} lg={12} sm={22} xs={24}>
            <OrderList data={data} />
          </Col>
          <Col xl={14} lg={12} sm={22} xs={24}>
            <OrderDetail data={data} />
          </Col>
        </Row>
      ) : (
        <Result
          status="404"
          title="ไม่พบสินค้าในตะกร้า"
          subTitle="กรุณาเลือกสินค้าก่อนยืนยันสินค้า"
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              ไปหน้าสินค้าทั้งหมด
            </Button>
          }
        />
      )}
    </div>
  );
}

export default ConfirmOrder;
