import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Select, Checkbox, Typography } from "antd";
import { TitleLightPurple } from "components/styled-component/TextStyle";
import { PriceNumber } from "functions";
import { RenderImage } from "functions";

const { Text } = Typography;
const { Option } = Select;
function BoxesItem(props) {
  const [valueModel, setValueModel] = useState("");
  const [colors, setColors] = useState([]);
  const { onIdBoxChange, onAtomModifyChange, parent } = props;
  const { type, value, isFree } = props.item;
  const item = value?.product_list;
  const [itemId, setItemId] = useState("");
  const [itemCheckBox, setItemCheckBox] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  useEffect(() => {
    if (type === "atom" && value.atom_list !== undefined) {
      value.atom_list.forEach((brand) => {
        brand.product_lists.forEach((model) => {
          if (model.id === item.id) {
            setValueModel(brand.id);
          }
        });
      });
    }
    const img = value.product_list?.product_list_images?.path
    setCurrentImage(img);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, item.id, value.brand]);
  useEffect(() => {
    if (type === "atom" && value.atom_list !== undefined) {
      const obj = value.atom_list.find((brand) => brand.id === valueModel);
      setColors(obj?.product_lists ?? []);
    } else if (type === "box" || type === "mod" || type === "pod") {
      const objItem = value?.product_in_set ?? [];
      const arrColor = objItem.map((item) => {
        return {
          id: item.id,
          color: item.color,
        };
      });
      setColors([...arrColor]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueModel, value.brand, type, value.product_list]);

  useEffect(() => {
    setItemId(item?.id);
    setItemCheckBox(value.atom_modify);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.product_list, value?.atom_modify]);

  const onIdChange = (e, index, type) => {
    setItemId(e);
    onIdBoxChange(e, index, type);
    const productList = type === "atom" ? colors : value.product_in_set;
    const found = productList.find((x) => x.id === e);
    setCurrentImage(found.product_list_images?.path);
  };
  const onCheckBoxChange = (e, index) => {
    setItemCheckBox(e.target.checked);
    onAtomModifyChange(e, index);
  };
  return (
    <DivRoot>
      <Row gutter={[16, 16]}>
        <Col xl={3} sm={5} xs={5}>
          <Image alt="cart" src={RenderImage(currentImage)} />
        </Col>
        <Col xl={21} sm={19} xs={19}>
          <Row>
            <Col xl={16} sm={15}>
              <div>
                <TitleLightPurple style={{ fontSize: "12px" }}>
                  {item.name}
                </TitleLightPurple>
              </div>
            </Col>
            <Col xl={8} sm={9} style={{ marginBottom: "5px" }}>
              <DivFree
                style={{
                  display: isFree === true ? "" : "none",
                  textAlign: "center",
                }}
              >
                สินค้าแถมฟรี
              </DivFree>
            </Col>
          </Row>
          <Row>
            <Col xl={10} sm={12} xs={12}>
              <DivPrice>
                <div style={{ display: "flex" }}>
                  <TextQty>{`จำนวน : ${item.qty}`}</TextQty>
                </div>
              </DivPrice>
            </Col>
            <Col xl={14} sm={12} xs={12} style={{ textAlign: "end" }}>
              <TextPrice
                style={{
                  display: item.isFree === true ? "none" : "",
                  marginRight: "0px",
                }}
              >
                {`฿${PriceNumber(item.price_show)}`}
              </TextPrice>
            </Col>
          </Row>
          <Row>
            <Col xl={24} sm={24} xs={24}>
              <Row
                gutter={[8, 8]}
                style={{
                  marginTop: "5px",
                  display: type !== "atom" ? "none" : "",
                }}
              >
                <Col xl={12} sm={12} xs={24}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="เลือกรุ่นของสินค้า"
                    value={valueModel}
                    onChange={(e) => {
                      setValueModel(e);
                      setItemId(null);
                    }}
                  >
                    {value.atom_list?.map((model) => (
                      <Option key={model.id} value={model.id}>
                        {model.name}
                      </Option>
                    ))}
                  </Select>
                  <Checkbox
                    checked={itemCheckBox}
                    style={{ marginTop: "1rem" }}
                    onChange={(e) => onCheckBoxChange(e, parent)}
                  >
                    <Text mark>พร้อมโม</Text>
                  </Checkbox>
                </Col>
                <Col xl={12} sm={12} xs={24}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="เลือกสีของสินค้า"
                    value={itemId}
                    onChange={(e) => onIdChange(e, parent, type)}
                  >
                    {colors.map((color) => (
                      <Option key={color.id} value={color.id}>
                        {color.color}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "5px",
                  display:
                    type === "atom" ||
                    type === "cotton" ||
                    type === "battery" ||
                    type === "charger" || 
                    type === 'coil'
                      ? "none"
                      : "",
                }}
              >
                <Col span={24}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="เลือกสีของสินค้า"
                    defaultValue={value.product_list?.id}
                    value={itemId}
                    onChange={(e) => onIdChange(e, parent, type)}
                  >
                    {colors.map((color) => (
                      <Option key={color.id} value={color.id}>
                        {color.color}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </DivRoot>
  );
}

export default BoxesItem;
const DivFree = styled.div`
  margin-left: 5px;
  border-radius: 5px;
  background: #52c419;
  color: white;
  padding: 1px 3px 1px 3px;
  font-size: 12px;
`;
const DivRoot = styled.div`
  padding: 1rem;
  background: white;
  height: 100%;
  border-radius: 5px;
  border: 1px dashed #e5e5e5;
`;
const TextPrice = styled.span`
  color: #4e4c4c;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;
const TextQty = styled.p`
  margin-bottom: 0.1rem;
  color: #000000;
  font-size: 12px;
`;
const DivPrice = styled.div`
  align-items: center;
`;
const Image = styled.img`
  height: auto;
  width: 100%;
  border-radius: 5px;
`;
